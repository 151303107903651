import { ConstructionOutlined } from "@mui/icons-material";
import { AppBar, Button, Checkbox, FormControlLabel, Grid, Paper, TextField, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useState, useEffect, useRef, useContext } from "react";
import { Link, useNavigate, useLocation, Navigate } from "react-router-dom";
import useAuth from "../../app/hooks/useAuth";
import jwt_decode from "jwt-decode";
import { isEmailValid } from "../../app/helpers/string-helpers";
import { nanoid } from "nanoid";

const Register = () => {
	const POST_REGISTER_FORM_URL = process.env.REACT_APP_ENDPOINT + "api/register/";
	const GET_CSRF_URL = process.env.REACT_APP_ENDPOINT + "api/set-csrf/";

	// const { auth, setAuth } = useAuth();

	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from?.pathname || "/";

	const userRef = useRef();
	const errRef = useRef();

	const [errorMessages, setErrorMessages] = useState();
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [login, setLogin] = useState("");
	const [email, setEmail] = useState("");
	const [pwd, setPwd] = useState("");
	const [rePwd, setRePwd] = useState("");
	const [rememberMe, setRememberMe] = useState(false);
	const [success, setSuccess] = useState(false);
	const [formIsValid, setFormIsValid] = useState(false);

	useEffect(() => {
		axios.get(GET_CSRF_URL, { withCredentials: true }).then((res) => {});

		setLogin((x) => "");
		setEmail((x) => "");
		setPwd((x) => "");
		setRePwd((x) => "");
	}, []);

	useEffect(() => {
		// setLogin(x=>"");
		// setEmail(x=>"");
		// setPwd(x=>"");
		// setRePwd(x=>"");

		calculateIfFormIsValid();
	}, [login, pwd, rePwd, email]);

	// useEffect(() => {
	// 	setErrorMessages(x=>"");
	// }, [login, pwd]);

	const renderErrorMessage = (name) =>
		name === errorMessages.name && <div className="error">{errorMessages.message}</div>;

	const handleChangeLogin = (e) => {
		e.preventDefault();
		setLogin((p) => {
			return e.target.value;
		});
	};
	const handleChangePwd = (e) => {
		e.preventDefault();
		setPwd((p) => e.target.value);
	};
	const handleChangeRePwd = (e) => {
		e.preventDefault();
		setRePwd((p) => e.target.value);
	};
	const handleChangeEmail = (e) => {
		e.preventDefault();
		setEmail((p) => e.target.value);
	};

	const calculateIfFormIsValid = () => {
		// setErrorMessages(()=>{return {name:false, message:""}})
		let tmpValid = true;
		if (login.length < 3) {
			// setErrorMessages(()=>{return {name:true, message:"Idendifiant trop court (4 caractères minimum)"}})
			tmpValid = false;
		}

		if (pwd.length <= 8 || rePwd !== pwd) {
			tmpValid = false;
		}

		if (!isEmailValid(email)) {
			tmpValid = false;
		}

		setFormIsValid(() => tmpValid);
	};

	const handleRegisterSubmit = async (e) => {
		// 	e.preventDefault();

		try {
			const data = {
				username: login,
				email: email,
				password: pwd,
				re_password: rePwd,
			};

			const config = {
				headers: {
					"content-type": "application/json",
				},
				withCredentials: true,
				xsrfCookieName: "csrftoken",
				xsrfHeaderName: "X-CSRFToken",
			};
			const response = await axios.post(POST_REGISTER_FORM_URL, JSON.stringify(data), config);
			console.log(response);

			setLogin(() => "");
			setEmail(() => "");
			setPwd(() => "");
			setRePwd(() => "");

			navigate("/login", { replace: true });
		} catch (err) {
			console.log(err);
		}
	};

	const renderForm = (
		<>
			<Box sx={{ display: "flex" }}>
				<AppBar position="fixed">
					<Toolbar>
						<div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
							<div>
								<Typography variant="h6" noWrap component="div">
									Création de compte Base Documentaire Ordesoft
								</Typography>
							</div>
							{/* <div>
								<AccountCircleOutlinedIcon fontSize="large" />
							</div> */}
						</div>
					</Toolbar>
				</AppBar>
				<Grid container direction="row" justifyContent="center">
					<Paper elevation={2} sx={{ mt: 20, padding: 4 }}>
						<Grid container direction="column">
							<section>
								<p ref={errRef}>{errorMessages}</p>
							</section>
							<Typography color="primary" sx={{ margin: "auto", fontSize: "24px" }}>
								Créer un compte
							</Typography>
							<TextField
								type={"text"}
								variant="standard"
								id={nanoid()}
								label="Identifiant"
								sx={{ width: "300px" }}
								value={login}
								autoComplete={"off"}
								onChange={handleChangeLogin}
								ref={userRef}
								required
							/>
							<TextField
								type={"email"}
								variant="standard"
								id={nanoid()}
								label="Email"
								sx={{ width: "300px", mt: 4 }}
								value={email}
								autoComplete={"off"}
								onChange={handleChangeEmail}
								ref={userRef}
								required
							/>
							<TextField
								variant="standard"
								label="Mot de passe"
								type="password"
								id={nanoid()}
								sx={{ width: "300px", mt: 4 }}
								value={pwd}
								autoComplete={"off"}
								onChange={handleChangePwd}
								ref={errRef}
								required
							/>
							<TextField
								variant="standard"
								label="Confirmation Mot de passe"
								type="password"
								id={nanoid()}
								sx={{ width: "300px", mt: 4 }}
								value={rePwd}
								autoComplete={"off"}
								onChange={handleChangeRePwd}
								ref={errRef}
								required
							/>

							{/* <div className="g-recaptcha" data-sitekey="6LcXH7AjAAAAALMfJPfo5DSjsL7ZDInBrgrZukUR"></div> */}
							{/* <FormControlLabel
								sx={{ mt: "20px" }}
								label="Se souvenir de moi"
								control={
									<Checkbox
										checked={rememberMe}
										onChange={(e) =>
											setRememberMe((oldVal) => {
												return !oldVal;
											})
										}
										// onChange={handleToggleRemberMe}
									/>
								}
							/> */}

							<Button
								type="submit"
								variant="outlined"
								sx={{ mt: 4, mb: 2 }}
								disabled={!formIsValid}
								onClick={handleRegisterSubmit}
							>
								S'enregistrer
							</Button>
							<Link to={"/login"} sx={{ mt: 4, mb: 2 }} align="right">
								Déjà un compte ?
							</Link>
						</Grid>
					</Paper>
				</Grid>
			</Box>
		</>
	);

	return renderForm;
};

export default Register;
