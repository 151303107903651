import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { nanoid } from "nanoid";
import React, { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";

const baseStyle = {
	flex: 1,
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	padding: "16px",
	borderWidth: 3,
	borderRadius: 2,
	borderColor: "#666",
	borderStyle: "dashed",
	backgroundColor: "#fff",
	color: "#666",
	outline: "none",
	transition: "border .24s ease-in-out",
	width: "300px",
	margin: "auto",
};

const focusedStyle = {
	borderColor: "#2196f3",
};

const acceptStyle = {
	borderColor: "#2ac747",
	backgroundColor: "#efefef",
};

const rejectStyle = {
	borderColor: "#ff1744",
	backgroundColor: "#efefef",
};

let fileUrl = "#";
let textBoxUpload = "Glisser un document ou cliquer pour sélectionner";

const DocumentUploader = ({ nom_original, typeFichier, handleSetFichier }) => {
	textBoxUpload = nom_original;

	const [files, setFiles] = useState([]);
	const [fileName, setFileName] = useState(() => {
		if (nom_original === "") {
			if (typeFichier === "source") {
				return "Fichier source (pptx, xlsx, docx ...)";
			} else {
				return "Fichier final (pdf, png, mp4 ...)";
			}
		}

		return nom_original;
	});

	textBoxUpload = fileName;

	const [fileType, setFileType] = useState("");

	const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
		// accept: {
		// 	"image/*": [],
		// 	"text/csv": [],
		// 	"application/pdf": [],
		// },
	});

	useEffect(() => {
		if (files.length > 0) handleSetFichier(files[0]);
	}, [files]);

	useEffect(() => {
		acceptedFiles.map((f) => {
			setFiles(() => [f]);
			setFileName(() => f.path);
			setFileType(() => f.type);

			// if (typeFichier != "source") {
	
			// 	if (mimeDisplayable.indexOf(f.type) >= 0) {

			// 		fileUrl = URL.createObjectURL(acceptedFiles[0]);

			// 		const prev = document.getElementById("doc-preview");
			// 		if (prev !== null) {
			// 			prev.src = fileUrl;
			// 			prev.hidden = false;
			// 		}
			// 	}
			// }
		});
	}, [acceptedFiles]);

	const style = useMemo(
		() => ({
			...baseStyle,
			...(isFocused ? focusedStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isFocused, isDragAccept, isDragReject]
	);

	// process file and file list
	const arrayFiles = files.map((file) => {
		//calcul size kB
		let size = 0;
		if (file.size <= 1024) {
			size = file.size + " B";
		} else if (file.size > 1000 && file.size < 1000000) {
			size = Math.round((file.size / 1000) * 10) / 10 + " KB";
		} else {
			size = Math.round((file.size / 1000000) * 10) / 10 + " MB";
		}

		// modifie le nom (espaces,caractères speciaux...)

		let sanitized = "";
		const regex = /\s/gi;
		let newFileName = file.path.replaceAll(regex, "_");
		textBoxUpload = file.path + " - " + size;

		return (
			<li key={nanoid()}>
				<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
					{file.path} - {size} # <span>{newFileName}</span>
				</Typography>
			</li>
		);
	});

	return (
		// <Box>
		// 	<section className="container">
		<div {...getRootProps({ style })}>
			<input {...getInputProps()} />
			<div style={{ wordBreak: "break-all", fontSize: "12px" }}>{textBoxUpload}</div>
		</div>
		// 	</section>
		// </Box>
	);
};

export default DocumentUploader;
