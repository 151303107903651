import { TreeItem, TreeView } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";

const CategorieTreeAdmin = ({ categorie, handleChangeCategory, padLeft }) => {
	const hasChildrens = categorie.sub_categories && categorie.sub_categories.length >= 0 ? true : false;

	const [label, setLabel] = useState("");
	const [comment, setComment] = useState("");
	const [ordre, setOrdre] = useState(0);
	useEffect(() => {
		setLabel(() => categorie.label);
		setComment(() => categorie.commentaire);
		setOrdre(() => categorie.ordre);
	}, []);

	const handleChangeLabel = (e, cat) => {
		e.preventDefault();
		setLabel(() => e.target.value);

		//
	};
	const handleChangeOrdre = (e, cat) => {
		e.preventDefault();
		setOrdre(() => e.target.value);

		//
	};
	const handleChangeComment = (e, cat) => {
		e.preventDefault();
		setComment(() => e.target.value);

		//
	};

	const handleKeyDownLabel = (e) => {
		if (e.code === "Enter" && e.target.value) {
			let tmpCat = JSON.parse(JSON.stringify(categorie));
			tmpCat.label = e.target.value;

			axios
				.put(UPDATE_LIBELE_CATEGORIE_ITEM_URL, JSON.stringify(tmpCat), {
					headers: {
						"Content-Type": "application/json",
					},
					xsrfCookieName: "csrftoken",
					xsrfHeaderName: "X-CSRFTOKEN",
					withCredentials: true,
				})
				.then((resp) => {
					if (resp.status === 201) {
						// dispatch it
						// dispatch(updateFiltreCustomItem(resp.data));
					}
				})
				.catch((reason) => {
					console.log(reason);
				});

			// const opt = { option: { id: null, label: e.target.value, active: true, filtre_custom: filtre.id } };
			// handleFiltresCustomChanged("createOption", opt, filtre.id);
			// setSelectedFilters(filtre.items.filter((i) => i.active));
		}
	};
	const handleKeyDownComment = (e) => {
		if (e.code === "Enter" && e.target.value) {
			let tmpCat = JSON.parse(JSON.stringify(categorie));
			tmpCat.commentaire = e.target.value;

			axios
				.put(UPDATE_LIBELE_CATEGORIE_ITEM_URL, JSON.stringify(tmpCat), {
					headers: {
						"Content-Type": "application/json",
					},
					xsrfCookieName: "csrftoken",
					xsrfHeaderName: "X-CSRFTOKEN",
					withCredentials: true,
				})
				.then((resp) => {
					if (resp.status === 201) {
						// dispatch it
						// dispatch(updateFiltreCustomItem(resp.data));
					}
				})
				.catch((reason) => {
					console.log(reason);
				});

			// const opt = { option: { id: null, label: e.target.value, active: true, filtre_custom: filtre.id } };
			// handleFiltresCustomChanged("createOption", opt, filtre.id);
			// setSelectedFilters(filtre.items.filter((i) => i.active));
		}
	};
	const handleKeyDownOrdre = (e) => {
		if (e.code === "Enter" && e.target.value) {
			let tmpCat = JSON.parse(JSON.stringify(categorie));
			tmpCat.ordre = e.target.value;

			axios
				.put(UPDATE_LIBELE_CATEGORIE_ITEM_URL, JSON.stringify(tmpCat), {
					headers: {
						"Content-Type": "application/json",
					},
					xsrfCookieName: "csrftoken",
					xsrfHeaderName: "X-CSRFTOKEN",
					withCredentials: true,
				})
				.then((resp) => {
					if (resp.status === 201) {
						// dispatch it
						// dispatch(updateFiltreCustomItem(resp.data));
					}
				})
				.catch((reason) => {
					console.log(reason);
				});

			// const opt = { option: { id: null, label: e.target.value, active: true, filtre_custom: filtre.id } };
			// handleFiltresCustomChanged("createOption", opt, filtre.id);
			// setSelectedFilters(filtre.items.filter((i) => i.active));
		}
	};

	return (
		<Box sx={{ paddingLeft: padLeft }}>
			<input type="number" onChange={(e) => handleChangeOrdre(e, categorie)} onKeyDown={handleKeyDownOrdre} style={{width:"40px", marginRight:"5px"}} value={ordre} />
			<input
				className="catTreeAdmin"
				type={"text"}
				onChange={(e) => handleChangeLabel(e, categorie)}
				value={label}
				onKeyDown={handleKeyDownLabel}
			/>{" "}
			<input
				className="catTreeAdmin"
				type={"text"}
				onChange={(e) => handleChangeComment(e, categorie)}
				value={comment}
				onKeyDown={handleKeyDownComment}
			/>
			{hasChildrens &&
				categorie.sub_categories.map((c) => (
					<CategorieTreeAdmin
						key={c.id + "_" + c.label}
						handleChangeCategory={handleChangeCategory}
						categorie={c}
						padLeft={"15px"}
					/>
				))}
		</Box>
	);
};

export const POST_CATEGORIE_ITEM_URL = process.env.REACT_APP_ENDPOINT + "api/documents/categorie";
export const UPDATE_LIBELE_CATEGORIE_ITEM_URL = process.env.REACT_APP_ENDPOINT + "api/documents/categorieupdate";
// export const UPDATE_LIBELE_CATEGORIE_ITEM_URL = process.env.REACT_APP_ENDPOINT + "api/documents/categorieupdate";

export default CategorieTreeAdmin;
