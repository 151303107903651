import React from "react";
import DocumentsLayout from "./document-layout/DocumentsLayout";

const Layout = () => {
	
	return (
		// <React.StrictMode>

			<DocumentsLayout />
			
		// </React.StrictMode>
	);
};

export default Layout;
