import { TextField } from "@mui/material";
import React, { useRef } from "react";
import { textColorGradident } from "../../../../app/helpers/const-helpers";
import { convertISOStringToFrDate } from "../../../../app/helpers/date-helpers";

import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import ReplyIcon from "@mui/icons-material/Reply";
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";

export const URL_POST_DOCUMENT_MESSAGE = process.env.REACT_APP_ENDPOINT + "api/documents/postmessage";

const Commentaire = ({ com, offset, level, updateComments }) => {
	const commentField = useRef();
	const messageField = useRef();
	const commentInput = useRef();

	const [reply, setReply] = useState("");

	const handleClickAddComment = (e, parentId) => {
		e.preventDefault();

		commentField.current.style.display = "flex";

		messageField.current.style.border = "1px dashed";
		messageField.current.style.paddingLeft = "5px";
		messageField.current.style.marginRight = "5px";

		commentInput.current.focus();
	};

	const sendCommentaire = (e) => {
		e.preventDefault();

		const data = { docid: com.document, parent: com.id, value: reply };
		axios
			.post(URL_POST_DOCUMENT_MESSAGE, JSON.stringify(data), {
				headers: {
					"Content-Type": "application/json",
				},
				withCredentials: true,
				xsrfCookieName: "csrftoken",
				xsrfHeaderName: "X-CSRFToken",
			})
			.then((r) => {
				messageField.current.style.border = "none";
				messageField.current.style.paddingLeft = "0px";
				messageField.current.style.marginRight = "0px";
				commentField.current.style.display = "none";

				updateComments(r.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const closeReply = (e)=>{
		e.preventDefault()
		messageField.current.style.border = "none";
		messageField.current.style.paddingLeft = "0px";
		messageField.current.style.marginRight = "0px";
		commentField.current.style.display = "none";
	}

	return (
		<div style={{ paddingLeft: offset + "px", color: textColorGradident[level] }}>
			<div ref={messageField}>
				<div
					className="document-comment-line"
					style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}
				>
					<div style={{maxWidth:"90%"}}>
						<div style={{fontSize:"10px", color:"#72444f"}}>{convertISOStringToFrDate(com.date)}</div>
						<div  style={{fontSize:"14px"}}>{com.commentaire}</div>
					</div>

					<div style={{ display: "flex", fontSize: "12px", paddingRight: "5px" }}>
						{/* <div>{convertISOStringToFrDate(com.date)}</div> */}
						<div
							style={{ cursor: "pointer", paddingLeft: "10px" }}
							onClick={(e) => handleClickAddComment(e, com.id)}
						>
							<ReplyIcon size="small" color="secondary" />
						</div>
					</div>
				</div>

				<div
					ref={commentField}
					style={{
						paddingLeft: offset + 20 + "px",
						display: "none",
						marginBottom: "10px",
						paddingRight: "20px",
						alignItems: "flex-end",
					}}
				>
					<TextField
						inputRef={commentInput}
						onChange={(e) => {
							setReply((p) => e.target.value);
						}}
						style={{ width: "100%" }}
						value={reply}
						label="Ajouter un commentaire"
						variant="standard"
						rows={1}
						multiline
					/>

					<div style={{ paddingLeft: "10px", height: "72px" }}></div>

					<SendOutlinedIcon onClick={sendCommentaire} sx={{ cursor: "pointer" }} />
					<div style={{width:"10px"}}></div>
					<ClearRoundedIcon color="secondary" onClick={closeReply} sx={{ cursor: "pointer" }} />
				</div>
			</div>

			{com.sub_commentaires.length > 0 ? (
				<div style={{ marginTop: "5px" }}>
					{" "}
					{com.sub_commentaires.map((c) => {
						return (
							<Commentaire
								key={c.id}
								com={c}
								offset={offset}
								level={level + 1}
								updateComments={updateComments}
							/>
						);
					})}
				</div>
			) : (
				<div style={{ marginTop: "10px" }}></div>
			)}
		</div>
	);
};

export default Commentaire;
