import {
	Autocomplete,
	Box,
	Chip,
	FormControl,
	FormHelperText,
	InputLabel,
	makeStyles,
	MenuItem,
	OutlinedInput,
	Select,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
// import {makeStyles} from "@mui/styles"
import id from "date-fns/esm/locale/id/index.js";
import React, { useState } from "react";
import axios from "axios";
import { addFiltreCustomItem } from "./filtresCustomListSlice";
import { useDispatch } from "react-redux";
import { margin } from "@mui/system";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { useEffect } from "react";
import { nanoid } from "nanoid";

/**
 *
 * clearAutocomplete : un changement de valeur dans le component (ici key)
 * force le reset du component.
 * clearAutovomplete change dans le parent quand on change de categorie
 *
 * @param {*} param0
 * @returns
 */
const FiltresCustomUpdateDocument = ({ filtre, handleFiltresCustomChanged, size, selectedFilters }) => {
	const [thisValue, setThisValue] = useState([]);

	useEffect(() => {
		if(filtre!=undefined){
			filtre.items.forEach((it) => {
				if (selectedFilters.indexOf(it.id) > -1) {
					setThisValue((df) => {
						return [...df, it];
					});
				}
			});
		}
		
	}, []);

	/**
	 *
	 * @param {*} e
	 * @param {*} v
	 * @param {*} r
	 * @param {*} d
	 * Methode utilisée pour l'utilisateur en recherche
	 */
	const handleChange = (e, v, r, d) => {
		e.preventDefault();
		setThisValue(p=>{return v});
		handleFiltresCustomChanged(e, r, d,filtre.id);
	};

	return (
		<>
			<Autocomplete
				size={size}
				className="filtre-custom"
				sx={{ mt:"20px",width: "100%" }}
				multiple
				autoComplete
				freeSolo
				filterSelectedOptions
				options={filtre.items.filter((it) => {
					return it.active;
				})}
				getOptionLabel={(f) => f.label || f}
				defaultValue={[]}
				value={thisValue}
				onChange={(e, v, r, d) => {
					handleChange(e, v, r, d);
				}}
				renderInput={(params) => <TextField {...params} variant="standard" label={filtre.label} />}
			/>
		</>
	);
};

export default FiltresCustomUpdateDocument;
