import {
	Alert,
	Autocomplete,
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	Checkbox,
	ClickAwayListener,
	Fade,
	FormControlLabel,
	LinearProgress,
	Paper,
	Popper,
	Snackbar,
	TextField,
	Typography,
} from "@mui/material";
import React, { useMemo, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useSelector, useDispatch } from "react-redux";
import { selectAllCategories } from "../categories/categoriesListSlice";
import { nanoid } from "@reduxjs/toolkit";
import { CategorieMenu } from "../categories/Categorie";

import axios from "axios";
// import { fetchFiltres, getFiltresError, getFiltresStatus, selectAllFiltres } from "../filtres/filtresListSlice";
import {
	fetchFiltresCustom,
	getFiltresCustomError,
	getFiltresCustomStatus,
	selectAllFiltresCustom,
} from "../filtres/filtres_custom/filtresCustomListSlice";
import { FiltresMenu } from "../filtres/Filtres";
import id from "date-fns/esm/locale/id/index.js";
import DocumentViewer from "./viewer/DocumentViewer";

import { addDocuments } from "./documentsListSlice";
// import { fetchKeywords, selectAllKeywords, getKeywordsStatus, getKeywordsError } from "../keywords/keywordsListSlice";
import { FiltresCustomMenu } from "../filtres/filtres_custom/FiltresCustom";
import CategorieTree from "../categories/CategorieTree";
import DocumentUploader from "./DocumentUploader";
import { Label } from "@mui/icons-material";

import { mimeDisplayable } from "../../../app/helpers/const-helpers";
import { useRef } from "react";
import { selectAllGroups } from "../groups/groupsListSlice";

// const mimeDisplayable = ["video/mp4", "video/mov", "image/png", "image/jpg", "image/jpeg", "application/pdf"];

const baseStyle = {
	flex: 1,
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	padding: "10px",
	borderWidth: 3,
	borderRadius: 2,
	borderColor: "#666",
	borderStyle: "dashed",
	backgroundColor: "#fff",
	color: "#666",
	outline: "none",
	transition: "border .24s ease-in-out",
	width: "300px",
};

const focusedStyle = {
	borderColor: "#2196f3",
};

const acceptStyle = {
	borderColor: "#2ac747",
	backgroundColor: "#efefef",
};

const rejectStyle = {
	borderColor: "#ff1744",
	backgroundColor: "#efefef",
};

const DocumentNew = () => {
	const POST_DOCUMENT_URL = process.env.REACT_APP_ENDPOINT + "api/documents/upload";

	const categories = useSelector(selectAllCategories);
	const groups = useSelector(selectAllGroups);
	const filtresCustom = useSelector(selectAllFiltresCustom);
	const filtresCustomStatus = useSelector(getFiltresCustomStatus);
	const filtresCustomError = useSelector(getFiltresCustomError);

	// const keywords = useSelector(selectAllKeywords);
	// const keywordsStatus = useSelector(getKeywordsStatus);
	// const keywordsError = useSelector(getKeywordsError);

	const [files, setFiles] = useState([]);
	const [filesSource, setFilesSource] = useState([]);

	const [isSuccess, setIsSuccess] = useState(false);
	const [progress, setProgress] = useState(0);

	const [flatCat, setFlatCat] = useState([]);

	const [fileName, setFileName] = useState("");
	const [fileType, setFileType] = useState("");

	const [selectedCat, setSelectedCat] = useState("");
	const [selectedCatLabel, setSelectedCatLabel] = useState("");

	const [anchorCatEl, setAnchorCatEl] = React.useState(null);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const [nomAffichage, setNomAffichage] = useState("");
	const [resume, setResume] = useState("");
	const [aCateg, setACateg] = useState([]);
	const [aFiltr, setAFiltr] = useState([]);
	const [docImportantChecked, setDdocImportantChecked] = useState(false);
	const [openCatSelector, setOpenCatSelector] = useState(false);
	const [originalFileName, setOriginalFileName] = useState("");
	const [aGroups,setAGroups] = useState([])

	const [fileDataUrl, setFileDataUrl] = useState("");

	const [openSnack, setOpenSnack] = useState(false);
	const [snackSeverity, setSnackSeverity] = useState("success");
	const [snackMessage, setSnackMessage] = useState("Document enregistré");

	const progressBar = useRef();

	const dispatch = useDispatch();

	useEffect(() => {

		setAGroups(g=>groups)
	}, [groups]);
	const handleChangeGroups = (e, v, r, d)=>{
		setAGroups(g=>v)

	}

	useEffect(() => {
		flattenCategories(categories);
	}, [flatCat]);

	/**
	 *
	 * @param {*} e
	 */
	const handleChangeNomAff = (e) => {
		setNomAffichage(() => e.target.value);
	};
	/**
	 *
	 * @param {*} e
	 */
	const handleChangeResume = (e) => {
		setResume(() => (e.target.value === "" ? " " : e.target.value));
	};
	/**
	 *
	 * @param {*} e
	 */
	const handleChangeCateg = (e) => {
		setACateg(e.map((c) => c.id));
	};

	/**
	 *
	 * @param {*} e
	 * @param {*} s
	 */
	const handleChangeCategory = (e, s) => {
		setSelectedCat(() => s.id); // pour je ne sais quoi ...
		setACateg(() => [s.id]); // pour l'enregistrement
		setSelectedCatLabel(""); // remise à 0 pour l'affichage
		constructCategoryPath(s);
	};

	/**
	 *
	 * @param {*} cat
	 */
	const constructCategoryPath = (cat) => {
		if (cat) {
			setSelectedCatLabel((prevPath) => {
				if (prevPath === "") return cat.label;
				else return prevPath != undefined ? cat.label + " / " + prevPath : cat.label;
			});

			if (cat.parent != null) {
				const parent = flatCat.filter((x) => {
					return x.id == cat.parent;
				});
				constructCategoryPath(parent[0]);
			}
		}
	};

	/**
	 *
	 */
	const handleClickAway = () => {
		setOpenCatSelector(false);
	};

	/**
	 *
	 * @param {*} event
	 */
	const handleClickCategorieTextField = (event) => {
		setAnchorCatEl(event.currentTarget);
		setOpenCatSelector(!openCatSelector);
	};
	/**
	 *
	 * @param {*} reason
	 * @param {*} detail
	 * @param {*} filtreId
	 */
	const handleFiltresCustomChanged = (reason, detail, filtreId) => {
		let tmpFilters = JSON.parse(JSON.stringify(aFiltr));

		// mise à jour des filtres selectionnés
		if (reason === "selectOption") {
			if (tmpFilters.indexOf(detail.option.id) < 0) {
				if (detail.option.id !== null) {
					tmpFilters.push(detail.option.id);
				}
			}
		} else if (reason === "removeOption") {
			tmpFilters.splice(tmpFilters.indexOf(detail.option.id), 1);
		}

		// maj state
		setAFiltr((p) => tmpFilters);
	};

	let textBoxUploadSource = "FICHER SOURCE (pptx, xlsx ...) \nGlisser un document ou cliquer pour sélectionner";
	let textBoxUploadPDF = "FICHIER VISIBLE (pdf, png, mp4 ...) \nGlisser un document ou cliquer pour sélectionner";

	const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({});

	

	/**
	 *
	 * @param {*} cats
	 */
	const flattenCategories = (cats) => {
		cats.map((c) => {
			const cc = { id: c.id, label: c.label, parent: c.parent };
			if (flatCat.map((m) => m.id).indexOf(cc.id) === -1) {
				setFlatCat([...flatCat, cc]);
			}

			if (c.sub_categories.length > 0) {
				flattenCategories(c.sub_categories);
			}
		});
	};

	

	// useEffect(() => {
	// 	acceptedFiles.map((f) => {
	// 		setFiles([f]);
	// 		setFileName(f.path);
	// 		setFileType(f.type);

	// 		setFileDataUrl((p) => URL.createObjectURL(acceptedFiles[0]));

	// 		const prev = document.getElementById("doc-preview");
	// 		if (prev !== null) {
	// 			prev.src = fileDataUrl;
	// 			prev.hidden = false;
	// 		}
	// 	});
	// }, [acceptedFiles]);

	const style = useMemo(
		() => ({
			...baseStyle,
			...(isFocused ? focusedStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isFocused, isDragAccept, isDragReject]
	);

	// process file and file list
	// const arrayFiles = files.map((file) => {
	// 	//calcul size kB
	// 	let size = 0;
	// 	if (file.size <= 1024) {
	// 		size = file.size + " B";
	// 	} else if (file.size > 1000 && file.size < 1000000) {
	// 		size = Math.round((file.size / 1000) * 10) / 10 + " KB";
	// 	} else {
	// 		size = Math.round((file.size / 1000000) * 10) / 10 + " MB";
	// 	}

	// 	// modifie le nom (espaces,caractères speciaux...)

	// 	let sanitized = "";
	// 	const regex = /\s/gi;
	// 	let newFileName = file.path.replaceAll(regex, "_");
	// 	textBoxUploadPDF = file.path + " - " + size;
	// 	return (
	// 		<li key={nanoid()}>
	// 			<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
	// 				{file.path} - {size} # <span>{newFileName}</span>
	// 			</Typography>
	// 		</li>
	// 	);
	// });

	/**
	 *
	 */
	const uploadFile = () => {
		progressBar.current.style.display = "block";

		const formData = new FormData();

		formData.append("file_object", files);
		formData.append("file_source_object", filesSource);

		const regex = /\s/gi;
		let newFileName = files.path.replaceAll(regex, "_");

		// setNomAffichage(nomAffichage.replaceAll(regex, ""));

		let json = {
			name: newFileName,
			resume: resume !== "" ? resume : "_",
			categories: aCateg,
			custom_filtre: aFiltr,
			display_name: nomAffichage !== "" ? nomAffichage : newFileName,
			mime: files.type,
			nom_original: files.path,
			important: docImportantChecked,
			allowed_groups: aGroups,
		};

		if( filesSource instanceof File)
			json["source_file_name"] = filesSource.path.replaceAll(regex, "_");

		formData.append("file_data", JSON.stringify(json));

		axios
			.post(POST_DOCUMENT_URL, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
				onUploadProgress: (progressEvent) => {
					const progress = (progressEvent.loaded / progressEvent.total) * 100;
					
					setProgress((p) => progress);
				},
				xsrfCookieName: "csrftoken",
				xsrfHeaderName: "X-CSRFTOKEN",
				withCredentials: true,
			})
			.then((r) => {
				if (r.status === 201) {
					setProgress((p) => progress);

					setResume((p) => "");
					setACateg((p) => []);
					setNomAffichage((p) => "");
					setFileType((p) => "");
					setFileName((p) => "");
					setAFiltr((p) => []);
					setFiles((p) => []);
					setAGroups((p)=>groups)
					setFileDataUrl((p) => "");
					setSelectedCatLabel((p) => "");
					setDdocImportantChecked((p) => false);
					setOriginalFileName(() => "");

					setOpenSnack((p) => true);
					setSnackSeverity((p) => "success");
					setSnackMessage((p) => "Document enregistré");

					dispatch(addDocuments(r.data));

					progressBar.current.style.display = "none";
				} else {
					setOpenSnack((p) => true);
					setSnackSeverity((p) => "error");
					setSnackMessage((p) => "Erreur lors de l'enregistrement");
					progressBar.current.style.display = "none";
				}
			})
			.catch((err) => {
				setOpenSnack((p) => true);
				setSnackSeverity((p) => "error");
				setSnackMessage((p) => err.message);
				progressBar.current.style.display = "none";
			});
	};

	/**
	 *
	 * @param {*} f
	 */
	const handleSetFichierSource = (f) => {
		setFilesSource((p) => f);
	};

	/**
	 *
	 * @param {*} f
	 */
	const handleSetFichier = (f) => {
		setFiles((p) => f);

		if (mimeDisplayable.indexOf(f.type) >= 0) {
			setFileDataUrl((p) => URL.createObjectURL(f));

			const prev = document.getElementById("doc-preview");
			if (prev !== null) {
				prev.src = fileDataUrl;
				prev.hidden = false;
			}
		}
	};

	/**
	 *
	 */
	const handleChangeDocImportant = () => {
		setDdocImportantChecked((p) => !!!p);
	};

	
	/**
	 *
	 */
	const handleCloseSnack = () => {
		setOpenSnack((p) => false);
	};

	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				open={openSnack}
				autoHideDuration={6000}
				onClose={handleCloseSnack}
			>
				<Alert onClose={handleCloseSnack} severity={snackSeverity} sx={{ width: "100%" }}>
					{snackMessage}
				</Alert>
			</Snackbar>
			<Card sx={{ mt: "84px", minWidth: 600, ml: "20px", mr: "20px" }}>
				<CardContent>
					<Typography sx={{ fontSize: 24 }} color="text.secondary" gutterBottom>
						Nouveau Document
					</Typography>

					<div style={{ display: "flex" }}>
						<div style={{ width: "55%" }}>
							<div>
								<div
									style={{
										display: "flex",
										justifyContent: "flex-start",
										alignItems: "center",
										marginLeft: "20px",
										marginTop: "20px",
									}}
								>
									<div>
										<span>Fichier source</span>
										<DocumentUploader
											nom_original={originalFileName}
											typeFichier={"source"}
											handleSetFichier={handleSetFichierSource}
										/>
									</div>
									<div style={{ marginLeft: "20px" }}></div>
									<div>
										<span>Fichier final visible par l'utilisateur</span>
										<DocumentUploader
											nom_original={originalFileName}
											typeFichier={"cible"}
											handleSetFichier={handleSetFichier}
										/>
									</div>
								</div>
							</div>

							<div style={{ display: "flex", marginTop: "20px" }}>
								<Box>
									<TextField
										sx={{ width: "280px", ml: "20px", mr: "20px" }}
										label="Nom d'affichage"
										placeholder="Nom d'affichage"
										helperText="Par défaut sera l'original"
										variant="standard"
										value={nomAffichage}
										onChange={(e) => handleChangeNomAff(e)}
									/>
								</Box>

								<FormControlLabel
									sx={{ color: "#666" }}
									control={
										<Checkbox
											checked={docImportantChecked}
											onChange={handleChangeDocImportant}
											size="small"
										/>
									}
									label={`Document important (listé en page d'accueil)`}
								/>
							</div>

							<div style={{ display: "flex" }}>
								<ClickAwayListener onClickAway={handleClickAway}>
									<Box>
										<TextField
											onClick={handleClickCategorieTextField}
											sx={{ width: "500px", margin: "20px", cursor: "pointer" }}
											id="id-rubrique"
											label="Catégorie"
											variant="standard"
											value={selectedCatLabel}
											InputProps={{
												readOnly: true,
											}}
										></TextField>

										<Popper open={openCatSelector} anchorEl={anchorCatEl} transition>
											{({ TransitionProps }) => (
												<Fade {...TransitionProps} timeout={50}>
													<Paper sx={{ padding: "10px" }}>
														{categories.length > 0 && (
															<Box>
																{categories.map((cat) => (
																	<CategorieTree
																		key={cat.label + "_" + cat.id}
																		categorie={cat}
																		handleChangeCategory={handleChangeCategory}
																	/>
																))}
															</Box>
														)}
													</Paper>
												</Fade>
											)}
										</Popper>
									</Box>
								</ClickAwayListener>
							</div>
							<div style={{ display: "flex", flexWrap: "wrap" }}>
								{filtresCustom.map((fc) => (
									<Box sx={{ margin: "20px" }} key={fc.id + "_" + fc.label}>
										<FiltresCustomMenu
											key={fc.id}
											filtre={fc}
											handleFiltresCustomChanged={(r, d, fid) =>
												handleFiltresCustomChanged(r, d, fid)
											}
										/>
									</Box>
								))}
							</div>
							<div style={{ display: "flex", flexWrap: "wrap" }}>
								<Autocomplete
									id={nanoid()}
									size="small"
									// key={clearAutocomplete}
									className="allowed-groups"
									// open={open}
									// onBlur={() => setOpen(() => false)}
									sx={{ minWidth:"300px",margin: "20px" }}
									multiple
									autoComplete
									freeSolo
									filterSelectedOptions
									options={groups}
									getOptionLabel={(g) => g.name }
									defaultValue={[]}
									// defaultValue={groups}
									onChange={(e, v, r, d) => {
										handleChangeGroups(e, v, r, d);
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											// onClick={() => setOpen(() => true)}
											variant="standard"
											size="small"
											label={"Visible par (VIDE = TOUS)"}
										/>
									)}
								/>
							</div>

							<div style={{ display: "flex" }}>
								<Box sx={{ margin: "20px" }}>
									<TextField
										sx={{ width: "600px" }}
										label="Résumé"
										placeholder="Résumé"
										multiline
										rows={3}
										variant="standard"
										value={resume}
										onChange={(e) => handleChangeResume(e)}
									/>
								</Box>
								{/* <Box sx={{ margin: "20px" }}>
								<TextField
									sx={{ width: "300px" }}
									label="Mots clés"
									placeholder="Mots clés"
									multiline
									rows={3}
									defaultValue=""
									variant="standard"
									onChange={(e) => handleChangeMotsCles(e)}
									// value={motscles.join(" ")}
								/>
							</Box> */}
							</div>
						</div>
						<div style={{ width: "45%", display: "flex", justifyContent: "center" }}>
							{/* <DocumentViewer fileData={fileUrl} fileName={fileName} fileType={fileType} /> */}

							{fileDataUrl.length > 10 ? ( // test de longueur arbitraire: si contient des données
								<iframe
									title="blblblb.pdf"
									id="doc-preview"
									src={fileDataUrl}
									style={{ width: "100%" }}
								/>
							) : (
								<iframe title="blblblb.pdf" id="doc-preview" style={{ width: "100%" }} hidden />
							)}
						</div>
					</div>
				</CardContent>
				<CardActions>
					<Button size="small" variant="outlined" onClick={uploadFile}>
						Valider
					</Button>
					<Box ref={progressBar} sx={{ display: "none", width: "40%", marginLeft: "10px" }}>
						<span>Upload des données : {`${Math.round(progress)}%`}</span>
						<LinearProgress variant="determinate" value={progress} />
					</Box>
				</CardActions>
			</Card>
		</>
	);
};

export default DocumentNew;
