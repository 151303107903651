import { TreeItem, TreeView } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import React from "react";

const CategorieTreeUpdateDocument = ({ categorie, handleChangeCategoryUpdate, padLeft }) => {
	const hasChildrens = categorie.sub_categories && categorie.sub_categories.length >= 0 ? true : false;

	const handleClick = (e, v) => {
		e.preventDefault();
		handleChangeCategoryUpdate(e, v);
	};

	return (
		<Box sx={{ paddingLeft: padLeft }}>
			<Typography className="cat-class-tree" onClick={(e) => handleClick(e, categorie)}>
				{categorie.label}
			</Typography>
			{hasChildrens &&
				categorie.sub_categories.map((c) => (
					<CategorieTreeUpdateDocument
						key={c.id + "_" + c.label}
						handleChangeCategoryUpdate={handleChangeCategoryUpdate}
						categorie={c}
						padLeft={"15px"}
					/>
				))}
		</Box>
	);
};

export default CategorieTreeUpdateDocument;
