import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { nanoid } from "nanoid";
import React, { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";

const baseStyle = {
	flex: 1,
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	padding: "10px",
	borderWidth: 3,
	borderRadius: 2,
	borderColor: "#666",
	borderStyle: "dashed",
	backgroundColor: "#fff",
	color: "#666",
	outline: "none",
	transition: "border .24s ease-in-out",
	width: "250px",
};

const focusedStyle = {
	borderColor: "#2196f3",
};

const acceptStyle = {
	borderColor: "#2ac747",
	backgroundColor: "#efefef",
};

const rejectStyle = {
	borderColor: "#ff1744",
	backgroundColor: "#efefef",
};

let fileUrl = "#";
let textBoxUpload = "Glisser un document ou cliquer pour sélectionner";

const DocumentUpdateUploader = ({nom_original,handleFilechanged, typeFichier}) => {
	textBoxUpload = nom_original
	if(nom_original===""){
		if(typeFichier==='source'){
			textBoxUpload = "Fichier source (pptx, xlsx, docx ...)"
		}else{
			textBoxUpload = "Fichier final (pdf, png,mp4 ...)"
		}
		
	}
	const [files, setFiles] = useState([]);
	const [fileName, setFileName] = useState("");
	const [fileType, setFileType] = useState("");

	const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
		// accept: {
		// 	"image/*": [],
		// 	"text/csv": [],
		// 	"application/pdf": [],
		// },
	});

	useEffect(() => {
		acceptedFiles.map((f) => {
			setFiles(p=>[f]);
			setFileName(p=>f.path);
			setFileType(p=>f.type);

			handleFilechanged(f)

			const prev = document.getElementById("doc-preview");
			if (prev !== null) {
				prev.src = fileUrl;
				prev.hidden = false;
			}
		});
	}, [acceptedFiles]);


	const style = useMemo(
		() => ({
			...baseStyle,
			...(isFocused ? focusedStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isFocused, isDragAccept, isDragReject]
	);

	// process file and file list
	const arrayFiles = files.map((file) => {
		//calcul size kB
		let size = 0;
		if (file.size <= 1024) {
			size = file.size + " B";
		} else if (file.size > 1000 && file.size < 1000000) {
			size = Math.round((file.size / 1000) * 10) / 10 + " KB";
		} else {
			size = Math.round((file.size / 1000000) * 10) / 10 + " MB";
		}

		// modifie le nom (espaces,caractères speciaux...)

		let sanitized = "";
		const regex = /\s/gi;
		let newFileName = file.path.replaceAll(regex, "_");
		textBoxUpload = file.path + " - " + size;
		return (
			<li key={nanoid()}>
				<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
					{file.path} - {size} # <span>{newFileName}</span>
				</Typography>
			</li>
		);
	});

	return (
		// <Box>
		// 	<section className="container">
				<div {...getRootProps({ style })}>
					<input {...getInputProps()} />
					<div style={{ wordBreak: "break-all", fontSize: "12px" }}>{textBoxUpload}</div>
				</div>
		// 	</section>
		// </Box>
	);
};

export default DocumentUpdateUploader;
