import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const DOCUMENTS_URL = process.env.REACT_APP_ENDPOINT + "api/documents";
const initialState = {
	documents: [],
	status: "idle",
	error: null,
};

export const fetchDocuments = createAsyncThunk("documents/fetchDocuments", async () => {
	const response = await axios.get(DOCUMENTS_URL, { withCredentials: true });
	return response.data;
});

const documentsListSlice = createSlice({
	name: "docsList",
	initialState,
	reducers: {
		addDocuments: (state, action) => {
			state.documents.push(action.payload);
		},
		updateFiltreCustomDoc: (state, action) => {
			state.documents.forEach((d, i) => {
				if (d.id === action.payload.did) {
					if (action.payload.r === "removeOption") {
						state.documents[i].custom_filtre.splice(d.custom_filtre.indexOf(action.payload.d), 1);
					} else if (action.payload.r === "selectOption") {
						state.documents[i].custom_filtre.push(action.payload.d);
					}
				}
			});
		},
		updateNomAffichage: (state, action) => {
			state.documents.forEach((d, i) => {
				if (d.id === action.payload.did) {
					state.documents[i].display_name = action.payload.v;
				}
			});
		},
		updateResume: (state, action) => {
			state.documents.forEach((d, i) => {
				if (d.id === action.payload.did) {
					state.documents[i].resume = action.payload.v;
				}
			});
		},
		updateDocCategorie: (state, action) => {
			state.documents.forEach((d, i) => {
				if (d.id === action.payload.did) {
					state.documents[i].categories = action.payload.v;
				}
			});
		},
		updateDocThumbnail: (state, action) => {
			state.documents.forEach((d, i) => {
				if (d.id === action.payload.did) {
					state.documents[i].thumbnail = action.payload.v;
				}
			});
		},
		updateDocBookmark: (state, action) => {
			state.documents.forEach((d, i) => {
				if (d.id === action.payload.did) {
					state.documents[i].bookmarked = action.payload.v;
				}
			});
		},
		updateDocuments: (state, action) => {
			state.documents.forEach((d, i) => {
				if (d.id === action.payload.id) {
					state.documents[i] = action.payload;
				}
			});
		},
	},
	extraReducers(builder) {
		builder
			.addCase(fetchDocuments.pending, (state, action) => {
				state.status = "loading";
			})
			.addCase(fetchDocuments.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.documents = action.payload;
			})
			.addCase(fetchDocuments.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			});
	},
});

export const selectAllDocuments = (state) => {
	return state.docsList.documents;
};
export const getDocsStatus = (state) => state.docsList.status;
export const getDocsError = (state) => state.docsList.error;

export const {
	addDocuments,
	updateFiltreCustomDoc,
	updateFileDoc,
	updateDocuments,
	updateNomAffichage,
	updateResume,
	updateDocCategorie,
	updateDocThumbnail,
	updateDocBookmark,
} = documentsListSlice.actions;

export default documentsListSlice.reducer;
