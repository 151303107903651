import { configureStore } from "@reduxjs/toolkit";
import categoriesListReducer from "../features/documents/categories/categoriesListSlice";
import documentsListReducer from "../features/documents/documents/documentsListSlice";
import groupsListReducer from "../features/documents/groups/groupsListSlice";
import keywordsListReducer  from "../features/documents/keywords/keywordsListSlice"
import filtresCustomListReducer  from "../features/documents/filtres/filtres_custom/filtresCustomListSlice"

export const store = configureStore({
	reducer: {
		categoriesList: categoriesListReducer,
		groupsList: groupsListReducer,
		docsList: documentsListReducer,
		filtresCustomList: filtresCustomListReducer,
		// keywordsList: keywordsListReducer,
	}
});