import React, { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
	Alert,
	ClickAwayListener,
	Divider,
	Fade,
	FormControl,
	InputLabel,
	MenuItem,
	Paper,
	Popper,
	Select,
	Snackbar,
	TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectAllCategories, updateCategories } from "./categoriesListSlice";
import { useEffect } from "react";
import axios from "axios";
import { TreeItem, TreeView } from "@mui/lab";
import CategorieTree from "./CategorieTree";
import CategorieTreeAdmin from "./CategorieTreeAdmin";

const CategorieNew = ({handleAddCategorie}) => {
	const POST_CATEGORIE_URL = process.env.REACT_APP_ENDPOINT + "api/documents/categories";

	const categories = useSelector(selectAllCategories);

	const dispatch = useDispatch();

	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState(false);

	const [catLabel, setCatLabel] = useState("");
	const [flatCat, setFlatCat] = useState([{ id: 0, label: "Pas de parent" }]);
	const [selectedCat, setSelectedCat] = useState("");
	const [selectedCatLabel, setSelectedCatLabel] = useState("");
	const [comCat, setComCat] = useState("");

	const [openSnack, setOpenSnack] = useState(false);
	const [snackSeverity, setSnackSeverity] = useState("success");
	const [snackMessage, setSnackMessage] = useState("Catégorie enregistrée");

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
		setOpen(!open);
	};

	const handleClickAway = () => {
		setOpen(false);
	};

	const flattenCategories = (cats) => {
		cats.map((c) => {
			const cc = { id: c.id, label: c.label };
			if (flatCat.map((m) => m.id).indexOf(cc.id) === -1) {
				setFlatCat([...flatCat, cc]);
			}

			if (c.sub_categories.length > 0) {
				flattenCategories(c.sub_categories);
			}
		});
	};

	useEffect(() => {
		flattenCategories(categories);
	}, [flatCat]);

	const handleChangeCategory = (e, s) => {
		setSelectedCat(s.id);
		setSelectedCatLabel(s.label);
	};
	const handleChangeLabel = (e) => {
		setCatLabel(e.target.value);
	};
	const handleChangeCom = (e) => {
		setComCat(e.target.value);
	};

	const postNewCategorie = async () => {
		const data = { label: catLabel, commentaire: comCat, parent: selectedCat };

		try {
			const res = await axios.post(POST_CATEGORIE_URL, JSON.stringify(data), {
				headers: {
					"Content-Type": "application/json",
				},
				xsrfCookieName: "csrftoken",
				xsrfHeaderName: "X-CSRFTOKEN",
				withCredentials: true,
			});

			if (res.status === 201) {
				

				setOpenSnack((p) => true);
				setSnackSeverity((p) => "success");
				setSnackMessage((p) => "Catégorie enrgistrée");

				handleAddCategorie(res.data)
			}

			setSelectedCat((p)=>null)
			setCatLabel((p) => "");
			setSelectedCatLabel((p) => "");
			setComCat((p) => "");
		} catch (err) {
			
			setOpenSnack((p) => true);
			setSnackSeverity((p) => "error");
			setSnackMessage((p) => err.request.responseText);
		}
	};

	const handleCloseSnack = () => {
		setOpenSnack((p) => false);
	};

	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				open={openSnack}
				autoHideDuration={6000}
				onClose={handleCloseSnack}
			>
				<Alert onClose={handleCloseSnack} severity={snackSeverity} sx={{ width: "100%" }}>
					{snackMessage}
				</Alert>
			</Snackbar>
			<Card sx={{ minWidth: 600 }}>
				<CardContent>
					<Typography sx={{ fontSize: 30 }} color="text.secondary">
						Administration des Categories
					</Typography>

					<Divider>
						<Typography sx={{ fontSize: 20 }} color="text.secondary">
							Nouvelle Categorie
						</Typography>
					</Divider>
					<br />
					<Box sx={{ display: "flex" }}>
						<TextField
							value={catLabel}
							onChange={handleChangeLabel}
							sx={{ width: 200 }}
							id="nom-rubrique"
							label="Nom"
							variant="standard"
						/>

						<ClickAwayListener onClickAway={handleClickAway}>
							<Box>
								<TextField
									onClick={handleClick}
									sx={{ marginLeft: 2, cursor: "pointer" }}
									id="id-rubrique"
									label="Catégorie parente"
									variant="standard"
									value={selectedCatLabel}
									InputProps={{
										readOnly: true,
									}}
								>
									Categorie
								</TextField>

								<Popper open={open} anchorEl={anchorEl} transition>
									{({ TransitionProps }) => (
										<Fade {...TransitionProps} timeout={50}>
											<Paper sx={{ padding: "10px" }}>
												{categories.length > 0 && (
													<Box>
														{categories.map((cat) => (
															<CategorieTree
																key={cat.label + "_" + cat.id + "_" + cat.parent}
																categorie={cat}
																handleChangeCategory={handleChangeCategory}
															/>
														))}
													</Box>
												)}
											</Paper>
										</Fade>
									)}
								</Popper>
							</Box>
						</ClickAwayListener>

						<TextField
							onChange={handleChangeCom}
							value={comCat}
							sx={{ width: 400, marginLeft: 2 }}
							id="com-rubrique"
							label="Commentaire"
							variant="standard"
							multiline
							maxRows={4}
						/>

						<Button size="small" variant="outlined" sx={{ marginLeft: 2 }} onClick={postNewCategorie}>
							Valider
						</Button>
					</Box>
				</CardContent>

				<CardContent>
					<br />
					<br />
					<Divider>
						<Typography sx={{ fontSize: 20 }} color="text.secondary">
							Modification Categories
						</Typography>
					</Divider>
					{categories.length > 0 && (
						<Box>
							{categories.map((cat) => (
								<CategorieTreeAdmin
									key={cat.label + "_" + cat.id + "_" + cat.parent}
									categorie={cat}
									handleChangeCategory={handleChangeCategory}
								/>
							))}
						</Box>
					)}
				</CardContent>
			</Card>
		</>
	);
};

export default CategorieNew;
