import React from "react";
import DocumentNew from "../documents/DocumentNew";

const newDocument = () => {
	return (
		<>
			<DocumentNew />
		</>
	);
};

export default newDocument;
