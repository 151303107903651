import { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import { NavLink, useNavigate } from "react-router-dom";
import Categorie from "../../features/documents/categories/Categorie";
import {
	selectAllCategories,
	getCategoriesError,
	getCategoriesStatus,
	fetchCategories,
} from "../../features/documents/categories/categoriesListSlice";
import {
	fetchDocuments,
	getDocsError,
	getDocsStatus,
	selectAllDocuments,
} from "../../features/documents/documents/documentsListSlice";
import {
	fetchFiltresCustom,
	getFiltresCustomError,
	getFiltresCustomStatus,
	selectAllFiltresCustom,
} from "../../features/documents/filtres/filtres_custom/filtresCustomListSlice";
import { Box } from "@mui/system";
import useAuth from "../../app/hooks/useAuth";
import { fetchGroups, getGroupsError, getGroupsStatus, selectAllGroups } from "../../features/documents/groups/groupsListSlice";

const DocumentsMenu = () => {
	const dispatch = useDispatch();

	const { auth } = useAuth();
	const [role, setRole] = useState([]);

	const categories = useSelector(selectAllCategories);
	const catError = useSelector(getCategoriesError);
	const catStatus = useSelector(getCategoriesStatus);

	const documents = useSelector(selectAllDocuments);
	const docError = useSelector(getDocsError);
	const docStatus = useSelector(getDocsStatus);

	const filtresCustom = useSelector(selectAllFiltresCustom);
	const filtresCustomStatus = useSelector(getFiltresCustomStatus);
	const filtresCustomError = useSelector(getFiltresCustomError);

	const groups = useSelector(selectAllGroups);
	const groupsStatus = useSelector(getGroupsStatus);
	const groupsError = useSelector(getGroupsError);

	const allCategories = {
		commentaire: null,
		// id: "all",
		id: -1,
		label: "Tous les documents",
		parent: null,
		sub_categories: [],
	};

	const navigation = useNavigate();

	useEffect(() => {
		let roles = [];
		if (auth?.user) {
			auth?.user.groups.forEach((g) => {
				roles.push(g.name);
			});
			setRole((p) => {
				return roles;
			});

		}

		if (catStatus === "idle") {
			dispatch(fetchCategories());
		}
		if (filtresCustomStatus === "idle") {
			dispatch(fetchFiltresCustom());
		}
		if (groupsStatus === "idle") {
			dispatch(fetchGroups());
		}
	}, [categories]);

	let content;
	if (catStatus === "loading") {
		content = <p>"Loading ..."</p>;
	} else if (catStatus === "succeded") {
		content = categories.map((cat) => <Categorie key={cat.id} cat={cat} offsetLeft={10} />);

		let contentAdmin = <></>;

		if (role.indexOf("SUPER_ADMIN") >= 0 || role.indexOf("ADMIN") >= 0) {
			contentAdmin = (
				<div style={{paddingTop:"10px",paddingLeft:"10px",paddingRight:"10px"}}>
					

					<ListItemButton className="listMenuItemButton" component={NavLink} to={"/dashboard"} sx={{ pl: "10px" }}>
						Tableau de bord
					</ListItemButton>

					<ListItemButton className="listMenuItemButton" component={NavLink} to={"/document/administration"} sx={{ pl: "10px" }}>
						Administration
					</ListItemButton>

					<ListItemButton className="listMenuItemButton" component={NavLink} to="/document/nouveau" sx={{ pl: "10px" }}>
						Nouveau Document
					</ListItemButton>

					<ListItemButton className="listMenuItemButton" component={NavLink} to={"/user/list"} sx={{ pl: "10px",mb:"10px" }}>
						Utilisateurs
					</ListItemButton>

					<Divider />
				</div>
			);
		}

		content = (
			<>
			<div id="content-menu-admin" >
				{contentAdmin}	
			</div >
				<Box sx={{ pt:"10px",pr: "10px", pl: "10px",pb:"40px"}}>
					<Categorie key={allCategories.id} cat={allCategories} offsetLeft={10} />
					{content}
					
				</Box>
			</>
		);
	} else if (catStatus === "failed") {
		content = <p>{catError}</p>;
		// TODO handle auth errors
		navigation("/login");
	} else {
		content = <p>{catStatus}</p>;
	}

	return <div>{content}</div>;
};

export default DocumentsMenu;
