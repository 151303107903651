import { ConstructionOutlined } from "@mui/icons-material";
import { AppBar, Button, Checkbox, FormControlLabel, Grid, Paper, TextField, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useState, useEffect, useRef, useContext } from "react";
import { Link, useNavigate, useLocation, Navigate } from "react-router-dom";
import useAuth from "../../app/hooks/useAuth";
import jwt_decode from "jwt-decode";

const Login = () => {
	const POST_LOGIN_FORM_URL = process.env.REACT_APP_ENDPOINT + "api/login/";
	const GET_CSRF_URL = process.env.REACT_APP_ENDPOINT + "api/set-csrf/";

	const { auth, setAuth } = useAuth();

	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from?.pathname || "/";

	const userRef = useRef();
	const errRef = useRef();

	const [errorMessages, setErrorMessages] = useState();
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [login, setLogin] = useState("");
	const [pwd, setPwd] = useState("");
	const [rememberMe, setRememberMe] = useState(false);
	const [success, setSuccess] = useState(false);

	useEffect(() => {

		axios.get(GET_CSRF_URL, { withCredentials: true }).then((res) => {});

		setLogin(x=>"");
		setPwd(x=>"");
		setRememberMe(() => {
			return false;
		});
		// userRef.current?.focus();
	}, []);

	useEffect(() => {
		setErrorMessages(x=>"");
	}, [login, pwd]);

	const renderErrorMessage = (name) =>
		name === errorMessages.name && <div className="error">{errorMessages.message}</div>;

	const handleChangeLogin = (e) => {
		e.preventDefault();
		setLogin(() => e.target.value);
	};
	const handleChangePwd = (e) => {
		e.preventDefault();
		setPwd(() => e.target.value);
	};
	const handleToggleRemberMe = (e) => {
		e.preventDefault();
		setRememberMe((oldVal) => {
			return !oldVal;
		});
	};

	const handleLoginSubmit = async (e) => {
		e.preventDefault();
		try {
			const data = { email: login, password: pwd, username: login };

			const config = {
				headers: {
					"content-type": "application/json",
				},
				withCredentials: true,
				xsrfCookieName: "csrftoken",
				xsrfHeaderName: "X-CSRFToken",
			};

			const response = await axios.post(POST_LOGIN_FORM_URL, JSON.stringify(data), config);
			
			
			const user = response?.data?.user?.u;

			setAuth({ user });
			const userLS = {email:user.email,first_name:user.first_name,last_name:user.last_name,username:user.username}
			localStorage.setItem('user',JSON.stringify(userLS))

			setLogin("");
			setPwd("");

			navigate("/", { replace: true });
		} catch (err) {
			
		}
	};

	const renderForm = (
		<>
			<Box sx={{ display: "flex" }}>
				<AppBar position="fixed">
					<Toolbar>
						<div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
							<div>
								<Typography variant="h6" noWrap component="div">
									Connexion Base Documentaire Ordesoft
								</Typography>
							</div>
							{/* <div>
								<AccountCircleOutlinedIcon fontSize="large" />
							</div> */}
						</div>
					</Toolbar>
				</AppBar>
				<Grid container direction="row" justifyContent="center">
					<Paper elevation={2} sx={{ mt: 20, padding: 4 }}>
						<Grid container direction="column">
							<section>
								<p ref={errRef}>{errorMessages}</p>
							</section>
							<Typography  color="primary" sx={{margin:"auto",fontSize:"24px"}}>Autentification</Typography>
							<TextField
								type={"text"}
								variant="standard"
								id="ged-id"
								label="Identifiant"
								sx={{ width: "300px", mt: 4 }}
								value={login}
								autoComplete={"off"}
								onChange={handleChangeLogin}
								ref={userRef}
								required
							/>
							<TextField
								variant="standard"
								label="Mot de passe"
								type="password"
								id="ged-pwd"
								sx={{ width: "300px", mt: 4 }}
								value={pwd}
								autoComplete={"off"}
								onChange={handleChangePwd}
								ref={errRef}
								required
							/>
							{/* <FormControlLabel
								sx={{ mt: "20px" }}
								label="Se souvenir de moi"
								control={
									<Checkbox
										checked={rememberMe}
										onChange={(e) =>
											setRememberMe((oldVal) => {
												return !oldVal;
											})
										}
										// onChange={handleToggleRemberMe}
									/>
								}
							/> */}

							<Button type="submit" variant="outlined" sx={{ mt: 4, mb: 2 }} onClick={handleLoginSubmit}>
								Connexion
							</Button>
							<Link to={"/register"} sx={{ mt: 4, mb:2,  }} align="right">Créer un compte ?</Link>
						</Grid>
					</Paper>
				</Grid>
			</Box>
		</>
	);

	return auth?.user ? <Navigate to="/document" state={{ from: "document" }} replace /> : renderForm;
};

export default Login;
