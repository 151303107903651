import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { convertISOStringToFrDate } from "../../../app/helpers/date-helpers";

const DocumentHistorique = ({ open, setOpen, historique }) => {
	const [historiqueState, setHistoriqueState] = useState([]);

	useEffect(() => {
		setHistoriqueState(historique);
	}, [historique]);
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Dialog open={open} onClose={handleClose} fullWidth={true}
        maxWidth={"lg"}>
			<DialogContent>
				<DialogContentText></DialogContentText>
				<TableContainer>
					<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
						<TableHead>
							<TableRow>
								<TableCell>Date</TableCell>
								<TableCell>Nom</TableCell>
								<TableCell>Source</TableCell>
								<TableCell>Utilisateur</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{
								historiqueState.map(h=>{
									return <TableRow key={h.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
									<TableCell component="th" scope="row">{convertISOStringToFrDate(h.date)}</TableCell>
									<TableCell component="th" scope="row">{h.name}</TableCell>
									<TableCell component="th" scope="row">{h.source_file_name}</TableCell>
									<TableCell component="th" scope="row">{h.user}</TableCell>
								</TableRow>
								})
							}
							
						</TableBody>
					</Table>
				</TableContainer>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Fermer</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DocumentHistorique;
