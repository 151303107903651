import React, { useMemo } from "react";
import { useState, useEffect } from "react";
import axios, { AxiosError } from "axios";
import { useDispatch, useSelector } from "react-redux";
import { parseISO, format } from "date-fns";
import {
	Box,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	CardMedia,
	Chip,
	CircularProgress,
	Collapse,
	IconButton,
	Menu,
	MenuItem,
	Typography,
} from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { selectAllFiltresCustom } from "../filtres/filtres_custom/filtresCustomListSlice";

import { ChatBubble, Visibility, VisibilityOutlined } from "@mui/icons-material";
import CommentIcon from '@mui/icons-material/Comment';
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import EditIcon from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import ModeCommentOutlinedIcon from "@mui/icons-material/ModeCommentOutlined";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import StarsIcon from "@mui/icons-material/Stars";
import StarOutlineIcon from "@mui/icons-material/StarOutline";

import DocDialog from "./DocumentDialog";
import ExpandMore from "@mui/icons-material/ExpandMore";
import useAuth from "../../../app/hooks/useAuth";
import { mimeDisplayable } from "../../../app/helpers/const-helpers";
import { Link } from "react-router-dom";
import { getFileExtension } from "../../../app/helpers/file-helpers";
import { updateDocBookmark, updateDocThumbnail } from "./documentsListSlice";
import DocumentHistorique from "./DocumentHistorique";
import CommentairesDialog from "./commentaire/CommentairesDialog";

export const URL_DOCUMENT = process.env.REACT_APP_ENDPOINT + "api/documents/download";
export const URL_DOCUMENT_SOURCE = process.env.REACT_APP_ENDPOINT + "api/documents/downloadsource";
export const URL_UPDATE_DOCUMENT = process.env.REACT_APP_ENDPOINT + "api/documents/update";
export const URL_DEACTIVATE_DOCUMENT = process.env.REACT_APP_ENDPOINT + "api/documents/deactivate";
export const URL_DOCUMENT_FILTERED_FULL_TEXT = process.env.REACT_APP_ENDPOINT + "api/documents/fts/?fulltext_search=";
export const URL_DOCUMENT_HISTORIQUE_COMPLET = process.env.REACT_APP_ENDPOINT + "api/documents/documenthistoriquecpt";
export const URL_UPDATE_BOOKMARK = process.env.REACT_APP_ENDPOINT + "api/documents/updatebookmark";
export const URL_GET_THUMBNAIL = process.env.REACT_APP_ENDPOINT + "api/documents/getthumbnail";
export const URL_GET_MESSAGES = process.env.REACT_APP_ENDPOINT + "api/documents/getmessages";

const DocInCard = ({ dataDoc, handleSetOpenUpdate, flatCategories }) => {
	const dispatch = useDispatch();

	const { auth } = useAuth();
	const [role, setRole] = useState([]);

	const [open, setOpen] = useState(false);
	const [openHistorique, setOpenHistorique] = useState(false);
	const [openCommentaire, setOpenCommentaires] = useState(false);

	const [documentBinary, setDocumentBinary] = useState(null);

	const [expanded, setExpanded] = useState(false);

	const [anchorEl, setAnchorEl] = useState(null);
	const openMenuCard = Boolean(anchorEl);

	const [kwChips, setKwChips] = useState([]);
	const [docPath, setDocPath] = useState("");

	const filtresCustom = useSelector(selectAllFiltresCustom);

	const [lastUpdate, setLastUpdate] = useState("");
	const [subtitle, setSubtitle] = useState("");

	const [isBookmarked, setIsBookmarked] = useState(false);

	const [thumbnail, setThumbnail] = useState("");
	const [thumbnailStatus, setThumbnailStatus] = useState(false);

	const [historique, setHistorique] = useState();

	const [commentaires, setCommentaires] = useState([]);

	const [binaryLoaded, setBinaryLoaded] = useState(false);

	let dateUpdate = parseISO(dataDoc.updated_at);

	/**
	 *
	 */
	useEffect(() => {
		let roles = [];
		if (auth?.user) {
			auth?.user.groups.forEach((g) => {
				roles.push(g.name);
			});
			setRole((p) => {
				return roles;
			});
		}

		let tmpKwChips = [];
		dataDoc.custom_filtre.forEach((f) => {
			filtresCustom.forEach((fc) => {
				fc.items.forEach((it) => {
					if (it.id === f) {
						tmpKwChips.push(it.label);
					}
				});
			});
		});
		setKwChips(tmpKwChips);

		setDocPath(() => "");
		flatCategories.forEach((fc) => {
			if (fc.id === dataDoc.categories[0]) {
				constructCategoryPath(fc);
				return;
			}
		});

		setLastUpdate(format(dateUpdate, "dd/MM/yyyy"));

		setIsBookmarked(() => dataDoc.bookmarked);

		getCommentaires();

		getThumbnail();
	}, []);

	useEffect(() => {
		getThumbnail();
	}, [dataDoc]);

	/**
	 *
	 */
	const getThumbnail = () => {
		setThumbnail((p) => dataDoc.thumbnail);
		if (dataDoc.thumbnail === undefined) {
			const data = { docid: dataDoc.id };
			axios
				.post(URL_GET_THUMBNAIL, JSON.stringify(data), {
					headers: {
						"Content-Type": "application/json",
					},
					withCredentials: true,
					xsrfCookieName: "csrftoken",
					xsrfHeaderName: "X-CSRFToken",
				})
				.then((r) => {
					setThumbnail((t) => r.data.thumbnail);
					setThumbnailStatus((p) => true);
					dispatch(updateDocThumbnail({ did: dataDoc.id, v: r.data.thumbnail }));
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			setThumbnailStatus((p) => true);
		}
	};

	/**
	 *
	 */
	const getCommentaires = () => {
		const data = { docid: dataDoc.id };
		axios
			.post(URL_GET_MESSAGES, JSON.stringify(data), {
				headers: {
					"Content-Type": "application/json",
				},
				withCredentials: true,
				xsrfCookieName: "csrftoken",
				xsrfHeaderName: "X-CSRFToken",
			})
			.then((r) => {
				setCommentaires((t) => r.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	/**
	 *
	 * @param {*} cat
	 */
	const constructCategoryPath = (cat) => {
		if (cat) {
			// console.log(cat.label)
			setDocPath((prevPath) => {
				if (prevPath === "") return cat.label;
				else return prevPath != undefined ? cat.label + " / " + prevPath : cat.label;
			});

			if (cat.parent != null) {
				const parent = flatCategories.filter((x) => {
					return x.id === cat.parent;
				});

				constructCategoryPath(parent[0]);
			}
		}
	};

	/**
	 *
	 */
	const handleClickOpen = () => {
		setBinaryLoaded((p) => false);
		setOpen(true);

		axios
			.post(
				URL_DOCUMENT,
				{ docid: dataDoc.id, checksum: dataDoc.checksum },
				{
					responseType: "blob",
					withCredentials: true,
					xsrfCookieName: "csrftoken",
					xsrfHeaderName: "X-CSRFToken",
				}
			)
			.then((r) => {
				const file = new Blob([r.data], { type: r.data.type });
				const fileURL = URL.createObjectURL(file);

				setDocumentBinary(() => fileURL);
				setBinaryLoaded((p) => true);
				// setOpen(true);
			});
	};

	/**
	 *
	 * @param {*} event
	 */
	const openCardMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	/**
	 *
	 */
	const closeCardMenu = () => {
		setAnchorEl(null);
	};

	/**
	 *
	 */
	const handleOpenUpdateDialog = () => {
		closeCardMenu();
		handleSetOpenUpdate(true, dataDoc);
	};
	/**
	 *
	 */
	const handleOpenHistoriqueDialog = () => {
		closeCardMenu();

		const data = JSON.stringify({ docid: dataDoc.id });
		axios
			.post(URL_DOCUMENT_HISTORIQUE_COMPLET, data, {
				headers: {
					"Content-Type": "application/json",
				},
				withCredentials: true,
				xsrfCookieName: "csrftoken",
				xsrfHeaderName: "X-CSRFToken",
			})
			.then((r) => {
				// console.log(r);
				// handleSetOpenHistorique(true, dataDoc);
				setHistorique((p) => r.data);
				setOpenHistorique(true);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const handleClickCommentaires = () => {
		setOpenCommentaires(true);
	};

	/**
	 *
	 */
	const handleClickBookmark = () => {
		const data = { docid: dataDoc.id, consultid: dataDoc.dc_id, bookmarked: !isBookmarked };
		axios
			.post(URL_UPDATE_BOOKMARK, JSON.stringify(data), {
				headers: {
					"Content-Type": "application/json",
				},
				withCredentials: true,
				xsrfCookieName: "csrftoken",
				xsrfHeaderName: "X-CSRFToken",
			})
			.then((r) => {
				setIsBookmarked((p) => !!!p);
				dispatch(updateDocBookmark({ did: dataDoc.id, v: !isBookmarked }));
			})
			.catch((err) => {});
	};
	/**
	 *
	 */
	const handleDeactivate = () => {
		closeCardMenu();
		const data = { docid: dataDoc.id };
		axios
			.post(URL_DEACTIVATE_DOCUMENT, JSON.stringify(data), {
				headers: {
					"Content-Type": "application/json",
				},
				withCredentials: true,
				xsrfCookieName: "csrftoken",
				xsrfHeaderName: "X-CSRFToken",
			})
			.then((r) => {})
			.catch((err) => {});
	};

	// pour afficher le resumé
	/**
	 *
	 */
	const handleExpandClick = () => {
		setExpanded((p) => !p);
	};

	/**
	 *
	 */
	const updateComments = (coms) => {
		setCommentaires((t) => coms);
	};

	let cardMedia = "";
	if (!thumbnailStatus) {
		cardMedia = (
			<Box sx={{ display: "flex", justifyContent: "center" }}>
				<CircularProgress />
			</Box>
		);
	} else {
		if (mimeDisplayable.indexOf(dataDoc.mime) >= 0) {
			cardMedia = cardMedia = (
				<CardMedia
					onClick={handleClickOpen}
					sx={{ cursor: "pointer", borderTop: "1px #ccc solid", borderBottom: "1px #ccc solid" }}
					component="img"
					height="80"
					src={`data:image/png;base64, ${thumbnail}`}
					// src={`data:image/png;base64, ${dataDoc.thumbnail}`}
				/>
			);
		} else {
			let url = "/img/rsz_1rsz_img_default_doc.png";
			if (["xlsx", "xls"].indexOf(getFileExtension(dataDoc.nom_original)) >= 0) {
				url = "/img/rsz_img_xlsx.png";
			}

			cardMedia = (
				<CardMedia sx={{ cursor: "pointer", borderTop: "1px #ccc solid", borderBottom: "1px #ccc solid" }}>
					<a href={URL_DOCUMENT + "/" + dataDoc.checksum} download target="_blank">
						<img src={url} width="300px" height="80px" />
					</a>
				</CardMedia>
			);
		}
	}

	const importantBackground = undefined;
	const importantTextColor = "#666";
	// const importantBackground = dataDoc.important?"#44725c":undefined
	// const importantTextColor = dataDoc.important?"#eee":undefined

	return (
		<>
			<DocDialog
				d={dataDoc.id}
				open={open}
				setOpen={setOpen}
				documentBinary={documentBinary}
				mimeType={dataDoc.mime}
				dataMessages={commentaires || []}
				allowed_groups={dataDoc.allowed_groups}
				path={docPath}
				binaryLoaded={binaryLoaded}
				filtresCustom={kwChips}
				resume={dataDoc.resume}
			/>
			<DocumentHistorique open={openHistorique} setOpen={setOpenHistorique} historique={historique || []} />
			<CommentairesDialog
				open={openCommentaire}
				setOpen={setOpenCommentaires}
				docid={dataDoc.id}
				commentaires={commentaires || []}
				updateComments={updateComments}
			/>
			{/* <div style={{display:"flex"}}> */}
			<Card sx={{ width: 300 }} elevation={4}>
				{role.indexOf("SUPER_ADMIN") >= 0 || role.indexOf("ADMIN") >= 0 ? (
					<CardHeader
						sx={{ wordBreak: "break-word", paddingBottom: "2px", background: importantBackground }}
						action={
							<>
								<IconButton
									aria-label="settings"
									aria-controls={openMenuCard ? "basic-menu" : undefined}
									aria-haspopup="true"
									aria-expanded={openMenuCard ? "true" : undefined}
									onClick={openCardMenu}
									sx={{ color: importantTextColor }}
								>
									<MoreVertIcon />
								</IconButton>
								<Menu
									id="basic-menu"
									anchorEl={anchorEl}
									open={openMenuCard}
									onClose={closeCardMenu}
									MenuListProps={{
										"aria-labelledby": "basic-button",
									}}
								>
									<MenuItem className="doc-card-menu-item" onClick={handleOpenUpdateDialog}>
										<EditIcon sx={{ color: "#999" }} />
										<span style={{ paddingLeft: "10px" }}>Modifier</span>
									</MenuItem>
									<MenuItem className="doc-card-menu-item" onClick={handleOpenHistoriqueDialog}>
										<HistoryIcon sx={{ color: "#999" }} />
										<span style={{ paddingLeft: "10px" }}>Historique</span>
									</MenuItem>
									<MenuItem className="doc-card-menu-item" onClick={handleDeactivate}>
										<DeleteOutlineIcon sx={{ color: "#999" }} />
										<span style={{ paddingLeft: "10px" }}>Désactiver</span>
									</MenuItem>
									<MenuItem className="doc-card-menu-item" onClick={closeCardMenu}>
										<a
											style={{ display: "flex" }}
											href={URL_DOCUMENT + "/" + dataDoc.checksum}
											download
											target="_blank"
										>
											<FileDownloadIcon sx={{ color: "#999" }} />
											<span style={{ paddingLeft: "10px" }}>Télécharger</span>
										</a>
									</MenuItem>

									{dataDoc.source_file_name != "" ? (
										<MenuItem className="doc-card-menu-item" onClick={closeCardMenu}>
											<a
												style={{ display: "flex" }}
												href={URL_DOCUMENT_SOURCE + "/" + dataDoc.checksum}
												download
												target="_blank"
											>
												<FileDownloadIcon sx={{ color: "#999" }} />
												<span style={{ paddingLeft: "10px" }}>Télécharger source</span>
											</a>
										</MenuItem>
									) : undefined}
								</Menu>
							</>
						}
						avatar={dataDoc.important ? <StarOutlineIcon fontSize="large" color="gold" /> : undefined}
						title={dataDoc.display_name}
						titleTypographyProps={{ color: importantTextColor, variant: "subtitle1" }}
					/>
				) : (
					<CardHeader
						sx={{ wordBreak: "break-word", paddingBottom: "2px" }}
						avatar={
							dataDoc.important ? (
								<StarOutlineIcon fontSize="large" sx={{ color: "orange" }} />
							) : undefined
						}
						title={dataDoc.display_name}
						titleTypographyProps={{ color: importantTextColor, variant: "subtitle1" }}
					/>
				)}

				<CardContent sx={{ display: "flex", justifyContent: "space-between", background: importantBackground }}>
					<Typography sx={{ fontSize: "10px", color: importantTextColor }}>{docPath}</Typography>
					<Typography sx={{ fontSize: "10px", color: importantTextColor }}>{lastUpdate}</Typography>
				</CardContent>

				<div>{cardMedia}</div>

				{kwChips.length > 0 && (
					<CardContent sx={{ padding: "5px", borderBottom: "1px #ccc solid" }}>
						<Box>
							{kwChips.map((k) => {
								return (
									<Chip
										label={k}
										sx={{
											fontSize: "10px",
											borderRadius: "10px",
											height: "20px",
											marginLeft: "3px",
										}}
										// variant="outlined"
										key={k}
									/>
								);
							})}
						</Box>
					</CardContent>
				)}

				<Collapse in={expanded} timeout={{ exit: 0 }} unmountOnExit>
					<CardContent style={{ padding: "10px" }}>
						<Typography style={{ wordBreak: "break-all", fontSize: "12px" }} color="text.secondary">
							{dataDoc.resume}
						</Typography>
					</CardContent>
				</Collapse>

				<CardContent sx={{ borderBottom: "1px #ccc solid", padding: "10px" }}>
					<div style={{ display: "flex" }}>
						<Typography
							display={expanded ? "none" : "initial"}
							sx={{ fontSize: "12px", lineHeight: 1 }}
							color="text.secondary"
						>
							{dataDoc.resume.length > 100 ? dataDoc.resume.substr(0, 100) + "..." : dataDoc.resume}
						</Typography>

						{dataDoc.resume.length > 100 && (
							<ExpandMore
								expand={expanded.toString()}
								onClick={handleExpandClick}
								aria-expanded={expanded}
								aria-label="show more"
							>
								<ExpandMoreIcon />
							</ExpandMore>
						)}
					</div>
				</CardContent>

				<CardActions sx={{ display: "flex", justifyContent: "space-between" }}>
					<div style={{ display: "flex" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<VisibilityOutlined sx={{ color: "#888" }} />
							<span style={{ color: "#666" }}>{dataDoc.nb_ouverture}</span>
						</div>
						<div style={{ marginLeft: "10px", display: "flex", alignItems: "center" }}>
							{commentaires.length > 0 ? (
								<>
									<CommentIcon
										onClick={handleClickCommentaires}
										sx={{ cursor: "pointer", color: "#06b81a" }}
									/>
									<span style={{ color: "#666" }}>{commentaires.length}</span>
								</>
							) : (
								<>
									<ModeCommentOutlinedIcon
										onClick={handleClickCommentaires}
										sx={{ cursor: "pointer", color: "#888" }}
									/>
									<span style={{ color: "#666" }}>{commentaires.length}</span>
								</>
							)}
						</div>
					</div>

					<BookmarkIcon
						onClick={handleClickBookmark}
						color={isBookmarked ? "gold" : "light_gray"}
						sx={{ cursor: "pointer" }}
					/>
				</CardActions>
			</Card>
		</>
	);
};

export default DocInCard;
