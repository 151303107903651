import { React } from "react";
import Layout from "./layout/layout";
import { Routes, Route, Navigate } from "react-router-dom";
import DocumentAdmin from "./features/documents/admin/admin";
import NewDocument from "./features/documents/admin/newDocument";
import DocumentNew from "./features/documents/documents/DocumentNew";
import Document from "./features/documents/documents/Document";
import DocumentUpdate from "./features/documents/documents/DocumentUpdate";
import Login from "./features/auth/Login";
import RequireAuth from "./features/auth/RequireAuth";
import Home from "./features/home/Home";
import UserList from "./features/user/UserList";
import useAuth from "./app/hooks/useAuth";
import DefaultLayout from "./layout/DefaultLayout";
import Register from "./features/auth/Register";
import Dashboard from "./features/dashboard/Dashboard";

const AppRoutes = () => {
	return (
		<Routes>
			<Route path="/">
				{/* PUBLIC ROUTES */}
				<Route path="/login" element={<Login />} />
				<Route path="/register" element={<Register />} />

				{/* PROTECTED ROUTES */}
				<Route element={<RequireAuth />}>
					<Route path="/" element={<Layout />}>
						<Route path="/" element={<Home />} />
						<Route path="/dashboard" element={<Dashboard />} />
					</Route>

					<Route path="/document" element={<Layout />}>
						<Route path="/document/documents/-1" element={<Document />} />
						<Route path="/document/documents/all" element={<Document />} />
						<Route path="/document/documents/favs" element={<Document />} />
						<Route path="/document/documents/imps" element={<Document />} />
						<Route path="/document/documents/*" element={<Document />} />
						<Route path="/document/nouveau" element={<NewDocument />}></Route>
						<Route path="/document/update/*" element={<DocumentUpdate />}></Route>
						<Route path="/document/administration" element={<DocumentAdmin />}></Route>
					</Route>

					<Route path="/user" element={<Layout />}>
						<Route path="/user/list" element={<UserList />} />
					</Route>
				</Route>

				{/* NOT FOUND (no corresponding routes above) */}
				<Route path="*" element={<Login />} />
			</Route>
		</Routes>
	);
};

function App() {
	return (
		<main className="App">
			<AppRoutes />
		</main>
	);
}

export default App;
