import {
	Card,
	CardContent,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import axios from "axios";
import { nanoid } from "nanoid";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { convertISOStringToFrDate } from "../../app/helpers/date-helpers";
import DocDialog from "../documents/documents/DocumentDialog";

const Home = () => {
	const GET_LAST_DOC_CREATED = process.env.REACT_APP_ENDPOINT + "api/documents/homedata";
	const URL_DOCUMENT = process.env.REACT_APP_ENDPOINT + "api/documents/download";

	const [lastCreated, setLastCreated] = useState([]);
	const [lastViewed, setLastViewed] = useState([]);
	const [lastImportant, setLastImportant] = useState([]);

	const [open, setOpen] = useState(false);
	const [documentBinary, setDocumentBinary] = useState(null);
	const [mime, setMime] = useState("");
	const [binaryLoaded, setBinaryLoaded] = useState(false);

	useEffect(() => {
		axios
			.post(
				GET_LAST_DOC_CREATED,
				{},
				{ xsrfCookieName: "csrftoken", xsrfHeaderName: "X-CSRFTOKEN", withCredentials: true }
			)
			.then((r) => {
				setLastCreated((c) => {
					return r.data.ajout;
				});
				setLastViewed((c) => {
					return r.data.consult;
				});
				setLastImportant((c) => {
					return r.data.important;
				});
			})
			.finally(() => {});
	}, []);

	/**
	 *
	 * @param {*} e
	 * @param {*} id
	 * @param {*} cs
	 */
	const handleClickOpen = (e, id, cs, mime) => {
		setDocumentBinary((p) => null);
		setBinaryLoaded((p) => false);
		// setOpen(true);
		axios
			.post(
				URL_DOCUMENT,
				{ docid: id, checksum: cs },
				{
					responseType: "blob",
					withCredentials: true,
					xsrfCookieName: "csrftoken",
					xsrfHeaderName: "X-CSRFToken",
				}
			)
			.then((r) => {
				const file = new Blob([r.data], { type: r.data.type });
				const fileURL = URL.createObjectURL(file);

				setMime((p) => mime);
				setDocumentBinary((p) => fileURL);
				setBinaryLoaded((p) => true);
				setOpen((p)=>true);
			});
	};

	return (
		<div style={{ padding: "20px", paddingTop: "84px" }}>
			<DocDialog
				open={open}
				setOpen={setOpen}
				documentBinary={documentBinary}
				mimeType={mime}
				binaryLoaded={binaryLoaded}
			/>

			<Card>
				<CardContent>
					<Typography sx={{ fontSize: 24 }} color="text.secondary">
						Derniers documents ajoutés / modifiés
					</Typography>
					<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
						<TableHead>
							<TableRow>
								<TableCell>Titre</TableCell>
								<TableCell>Date Création</TableCell>
								<TableCell>Date Modification</TableCell>
								<TableCell>Fichier</TableCell>
								{/* <TableCell>Fichier source</TableCell> */}
								<TableCell>Categories</TableCell>
								<TableCell>Filtres</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{lastCreated.map((row) => {
								return (
									<TableRow
										key={nanoid()}
										onClick={(e) => handleClickOpen(e, row.id, row.checksum, row.mime)}
										sx={{
											"&:last-child td, &:last-child th": { border: 0 },
											"&:hover": { background: "#eee", cursor: "pointer" },
										}}
									>
										<TableCell>{row.display_name}</TableCell>
										<TableCell>{convertISOStringToFrDate(row.created_at)}</TableCell>
										<TableCell>{convertISOStringToFrDate(row.updated_at)}</TableCell>
										<TableCell>{row.nom_original}</TableCell>
										{/* <TableCell>{row.source_file_name}</TableCell> */}
										<TableCell></TableCell>
										<TableCell></TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</CardContent>
			</Card>

			<Card sx={{ marginTop: "10px" }}>
				<CardContent>
					<Typography sx={{ fontSize: 24 }} color="text.secondary">
						Derniers documents consultés
					</Typography>
					<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
						<TableHead>
							<TableRow>
								<TableCell>Titre</TableCell>
								<TableCell>Date Création</TableCell>
								<TableCell>Date Modification</TableCell>
								<TableCell>Fichier</TableCell>
								{/* <TableCell>Fichier source</TableCell> */}
								<TableCell>Categories</TableCell>
								<TableCell>Filtres</TableCell>
								<TableCell>Nombre ouvertures</TableCell>
								<TableCell>Date dernière consultation</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{lastViewed.map((row) => {
								return (
									<TableRow
										key={nanoid()}
										onClick={(e) =>
											handleClickOpen(
												e,
												row.document.id,
												row.document.checksum,
												row.document.mime
											)
										}
										sx={{
											"&:last-child td, &:last-child th": { border: 0 },
											"&:hover": { background: "#eee", cursor: "pointer" },
										}}
									>
										<TableCell>{row.document.display_name}</TableCell>
										<TableCell>{convertISOStringToFrDate(row.document.created_at)}</TableCell>
										<TableCell>{convertISOStringToFrDate(row.document.updated_at)}</TableCell>
										<TableCell>{row.document.nom_original}</TableCell>
										{/* <TableCell>{row.document.source_file_name}</TableCell> */}
										<TableCell></TableCell>
										<TableCell></TableCell>
										<TableCell>{row.nombre_vue}</TableCell>
										<TableCell>{convertISOStringToFrDate(row.date)}</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</CardContent>
			</Card>

			<Card sx={{ marginTop: "10px" }}>
				<CardContent>
					<Typography sx={{ fontSize: 24 }} color="text.secondary">
						Derniers documents Importants
					</Typography>
					<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
						<TableHead>
							<TableRow>
								<TableCell>Titre</TableCell>
								<TableCell>Date Création</TableCell>
								<TableCell>Date Modification</TableCell>
								<TableCell>Fichier</TableCell>
								{/* <TableCell>Fichier source</TableCell> */}
								<TableCell>Categories</TableCell>
								<TableCell>Filtres</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{lastImportant.map((row) => {
								return (
									<TableRow
										key={nanoid()}
										onClick={(e) => handleClickOpen(e, row.id, row.checksum, row.mime)}
										sx={{
											"&:last-child td, &:last-child th": { border: 0 },
											"&:hover": { background: "#eee", cursor: "pointer" },
										}}
									>
										<TableCell>{row.display_name}</TableCell>
										<TableCell>{convertISOStringToFrDate(row.created_at)}</TableCell>
										<TableCell>{convertISOStringToFrDate(row.updated_at)}</TableCell>
										<TableCell>{row.nom_original}</TableCell>
										{/* <TableCell>{row.source_file_name}</TableCell> */}
										<TableCell></TableCell>
										<TableCell></TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</CardContent>
			</Card>
		</div>
	);
};

export default Home;
