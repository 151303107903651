import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const CATEGORIES_URL = process.env.REACT_APP_ENDPOINT + "api/documents/categories";

const initialState = {
	selected: -1,
	categories: [],
	status: "idle",
	error: null,
};

export const fetchCategories = createAsyncThunk("categories/fetchCategories", async () => {
	const response = await axios.get(CATEGORIES_URL, { withCredentials: true });
	return response.data;
});

/**
 *
 * @param {*} parentId
 * @param {*} categorie
 * @returns
 */


const categoriesListSlice = createSlice({
	name: "categoriesList",
	initialState,
	reducers: {
		setCategorieSelected: (state, action) => {
			state.selected = action.payload;
		},

		addCategorie: (state, action) => {
			
			state.categories = action.payload
		},
		updateCategories: (state, action) => {
			state.categories.map((c) => {
				if (c.id === action.payload.parent) {
					c.sub_categories.push(action.payload);
				}
			});
		},
	},
	extraReducers(builder) {
		builder
			.addCase(fetchCategories.pending, (state, action) => {
				state.status = "loading";
			})
			.addCase(fetchCategories.fulfilled, (state, action) => {
				state.status = "succeded";
				state.categories = action.payload;
			})
			.addCase(fetchCategories.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			});
	},
});

export const selectAllCategories = (state) => state.categoriesList.categories;
export const getCategoriesStatus = (state) => state.categoriesList.status;
export const getCategoriesError = (state) => state.categoriesList.error;
export const getCategorieSelected = (state) => state.categoriesList.selected;

export const { setCategorieSelected, updateCategories, addCategorie } = categoriesListSlice.actions;

export default categoriesListSlice.reducer;
