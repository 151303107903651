import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { BrowserRouter, Routes } from "react-router-dom";
import { AuthProvider } from "./app/context/AuthProvider";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const root = ReactDOM.createRoot(document.getElementById("root"));

const theme = createTheme({
	palette: {
		primary: {
			main: "#445a72",
			light:"#e2e7f0"
		},
		secondary:{
			main:"#72444f",
			light:"#e0dede"
		},
		tertiary:{
			main:"#726d44"
		},
		gold:{
			main:"#dba72e"
		},
		light_gray:{
			main:"#ccc"
		}
	},
	components: {
		MuiTooltip: {
		  styleOverrides: {
			tooltip: {
			  backgroundColor: '#eee',
			  color: '#333',
			  border: '1px solid #dadde9',
			},
		  },
		},
	  },
});

root.render(
	<BrowserRouter>
		<ThemeProvider theme={theme}>
			<AuthProvider>
				<Provider store={store}>
					<App />
				</Provider>
			</AuthProvider>
		</ThemeProvider>
	</BrowserRouter>
);
