import {
	Autocomplete,
	Box,
	Chip,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
} from "@mui/material";
import React, { useState } from "react";

const Filtres = () => {
	return <div>Filtres</div>;
};

export const FiltresMenu = ({ filtres, handleFiltresChanged }) => {
	const [filtresSelected, setFiltresSelected] = useState([]);

	const handleChange = (e,c) => {
		e.preventDefault()

		setFiltresSelected(c);
		handleFiltresChanged(c)
	};


	return (
		<FormControl >
			<Autocomplete
				sx={{ width: "300px" }}
				multiple
				options={filtres}
				getOptionLabel={(option) => option.label}
				defaultValue={[]}
				onChange={(e,v)=>{handleChange(e,v)}}
				renderInput={(params) => <TextField {...params} variant="standard" label="Filtres" />}
				
			/>
			<FormHelperText>Pour affiner la recherche</FormHelperText>
		</FormControl>
	);
};

export default Filtres;
