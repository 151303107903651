import {
	Box,
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	LinearProgress,
} from "@mui/material";
import axios from "axios";
import { nanoid } from "nanoid";
import { useEffect, useState, useRef } from "react";
import CommentaireSmall from "./commentaire/CommentaireSmall";
import SimpleDummyColumn from "./commentaire/SimpleDummyColumn";

const DocDialog = ({
	d,
	open,
	setOpen,
	documentBinary,
	mimeType,
	dataMessages,
	allowed_groups,
	path,
	binaryLoaded,
	filtresCustom,
	resume,
}) => {
	const URL_GET_MESSAGES = process.env.REACT_APP_ENDPOINT + "api/documents/getmessages";
	const [openLoc, setOpenLoc] = useState(true);
	const [fullWidth, setFullWidth] = useState(true);
	const [maxWidth, setMaxWidth] = useState("xl");
	const [mime, setMime] = useState("none/none");

	const [messages, setMessages] = useState([]);

	const [bin, setBin] = useState(documentBinary);
	const [loadingBarVisibility, setLoadingBarVisibility] = useState(binaryLoaded);

	const embedRef = useRef();
	const commentRef = useRef();
	const infosRef = useRef();

	const mimeTypesMap = {
		"application/pdf": "pdf",
		"image/png": "img",
		"image/jpg": "img",
		"image/jpeg": "img",
		"video/mp4": "vid",
		"video/mov": "vid",
	};

	useEffect(() => {
		setMessages((p) => dataMessages);
	}, [dataMessages]);

	useEffect(() => {
		setLoadingBarVisibility((p) => binaryLoaded);
		if (binaryLoaded) {
			setBin((p) => documentBinary);
		}
	}, [binaryLoaded]);

	useEffect(() => {
		setMime(() => (mimeTypesMap[mimeType] ? mimeTypesMap[mimeType] : null));
		setOpenLoc(open);
	}, [open]);

	const handleClose = () => {
		setOpen(false);
	};

	const handleMaxWidthChange = (event) => {
		setMaxWidth(event.target.value);
	};

	const handleFullWidthChange = (event) => {
		setFullWidth(event.target.checked);
	};

	const toggleComments = () => {
		if (commentRef.current.style.display === "none" || commentRef.current.style.width === "0%") {
			commentRef.current.style.display = "block";
			commentRef.current.style.transitionDuration = "300ms";
			commentRef.current.style.transitionProperty = "width";
			commentRef.current.style.width = "40%";
		} else {
			commentRef.current.style.width = "0%";
			commentRef.current.style.display = "none";
		}
	};
	const toggleInfos = () => {
		if (infosRef.current.style.display === "none" || commentRef.current.style.height === "0%") {
			infosRef.current.style.display = "block";
			infosRef.current.style.transitionDuration = "300ms";
			infosRef.current.style.transitionProperty = "height";
			infosRef.current.style.width = "70%";
		} else {
			infosRef.current.style.width = "0%";
			infosRef.current.style.display = "none";
		}
	};

	let container = <></>;
	if (mime === "vid") {
		container = (
			<video controls width="90%">
				<source src={bin} type="video/mp4" />
			</video>
		);
	} else if (mime === "pdf") {
		container = <embed type="application/pdf" src={bin} width="100%" height="700" />;
	} else if (mime === "img") {
		container = <embed src={bin} width="100%" height="700" />;
		// container = <img src={documentBinary} width="90%" style={{ maxWidth: "100%", maxHeight: "100%" }} />;
	}

	return (
		<Dialog open={open} onClose={handleClose} fullWidth={fullWidth} maxWidth={maxWidth}>
			<DialogContent sx={{ overflowY: "hidden" }}>
				<Box sx={{ width: "100%" }} display={loadingBarVisibility ? "none" : "block"}>
					<LinearProgress />
				</Box>
				<div style={{ display: "flex" }}>
					<div ref={embedRef} style={{ width: "100%" }}>
						{container}
					</div>

					<div
						ref={commentRef}
						style={{ display: "none", width: "0%" }}
						className={"overSmallMessagesContainer"}
					>
						<CommentaireSmall messages={messages} />
					</div>
				</div>
				{/* <Box></Box> */}
			</DialogContent>
			<DialogActions sx={{ pl: "30px", pr: "30px", justifyContent: "space-between" }}>
				<div ref={infosRef} style={{ maxWidth: "70%", display: "none" }}>
					<div style={{ paddingBottom: "5px" }}>
						{resume.length > 1 ? <div style={{ color: "#666" }}>{resume}</div> : undefined}
					</div>
					<div style={{ paddingBottom: "5px" }}>
						{path != undefined && path != "" && (
							<>
								Rubrique : <span style={{ marginLeft: "10px", color: "#666" }}>{path}</span>
							</>
						)}
					</div>
					<div style={{ paddingBottom: "5px" }}>
						{allowed_groups != undefined ? (
							allowed_groups.length == 0 ? (
								<div>
									Visible par :{" "}
									<Chip
										label={"Tous"}
										sx={{
											fontSize: "14px",
											borderRadius: "14px",
											height: "28px",
											marginLeft: "3px",
											border: "1px solid #ccc",
										}}
										// variant="outlined"
										key={nanoid()}
									/>
								</div>
							) : (
								<div>
									Visible par :{" "}
									{allowed_groups.map((g) => {
										return (
											<Chip
												label={g.name}
												sx={{
													fontSize: "14px",
													borderRadius: "14px",
													height: "28px",
													marginLeft: "3px",
													border: "1px solid #ccc",
												}}
												// variant="outlined"
												key={nanoid()}
											/>
										);
										// return (
										// 	<span style={{ marginLeft: "10px", color: "#666" }} key={nanoid()}>
										// 		-{g.name}
										// 	</span>
										// );
									})}
								</div>
							)
						) : undefined}
					</div>
					<div style={{ paddingBottom: "5px" }}>
						{filtresCustom.length > 0 ? (
							<div>
								Filtres :
								{filtresCustom.map((f) => {
									return (
										<Chip
											label={f}
											sx={{
												fontSize: "14px",
												borderRadius: "14px",
												height: "28px",
												marginLeft: "3px",
												border: "1px solid #ccc",
											}}
											// variant="outlined"
											key={nanoid()}
										/>
									);
									// return <span style={{ marginLeft: "10px", color: "#666" }} key={nanoid()}>{f}</span>;
								})}
							</div>
						) : undefined}
					</div>
				</div>
				<div></div>
				<div>
					<Button variant="outlined" color="secondary" onClick={toggleInfos} sx={{ mr: "10px" }}>
						Informations
					</Button>
					<Button variant="outlined" color="secondary" onClick={toggleComments} sx={{ mr: "10px" }}>
						Commentaires
					</Button>
					<Button variant="outlined" onClick={handleClose}>
						Fermer
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	);
};

export default DocDialog;
