import React, { useState } from "react";
import CategorieNew from "../categories/CategorieNew";
import FiltreNew from "../filtres/FiltreNew";
import FiltreCustomNew from "../filtres/filtres_custom/FiltreCustomNew";

import { useDispatch, useSelector } from "react-redux";
import {
	addFiltreCustomItem,
	updateFiltreCustomItem,
	fetchFiltresCustom,
	getFiltresCustomError,
	getFiltresCustomStatus,
	selectAllFiltresCustom,
	updateFiltreCustom,
	addFiltreCustom,
} from "../filtres/filtres_custom/filtresCustomListSlice";
import { Box, Card, CardContent, CardHeader, Divider, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import {
	UPDATE_FILTRE_CUSTOM_ITEM_URL,
	FiltresCustomMenu,
	POST_FILTRE_CUSTOM_ITEM_URL,
	UPDATE_FILTRE_CUSTOM_VISIBILITY_URL,
} from "../filtres/filtres_custom/FiltresCustom";
import axios from "axios";
import { addCategorie } from "../categories/categoriesListSlice";

const DocumentAdmin = () => {
	const filtresCustom = useSelector(selectAllFiltresCustom);
	const filtresCustomStatus = useSelector(getFiltresCustomStatus);
	const filtresCustomError = useSelector(getFiltresCustomError);

	const [filtreCustomState, setFiltreCustomState] = useState([]);

	const dispatch = useDispatch();

	useEffect(() => {
		if (filtresCustomStatus === "succeded") {
			setFiltreCustomState((p) => filtresCustom);
		}
	}, [filtresCustom]);

	/**
	 *
	 * @param {*} reason
	 * @param {*} detail
	 * @param {*} filtreId
	 * TODO place axios into reducers?
	 */
	const handleFiltresCustomChanged = (reason, detail, filtreId) => {
		if (reason === "createOption") {
			axios
				.post(POST_FILTRE_CUSTOM_ITEM_URL, JSON.stringify(detail.option), {
					headers: {
						"Content-Type": "application/json",
					},
					withCredentials: true,
					xsrfCookieName: "csrftoken",
					xsrfHeaderName: "X-CSRFToken",
				})
				.then((resp) => {
					if (resp.status === 201) {
						dispatch(addFiltreCustomItem(resp.data));
					}
				})
				.catch((reason) => {});
		} else if (reason === "removeOption") {
			let tmpF = JSON.parse(JSON.stringify(detail.option));
			tmpF.active = false;
			axios
				.put(UPDATE_FILTRE_CUSTOM_ITEM_URL, JSON.stringify(tmpF), {
					headers: {
						"Content-Type": "application/json",
					},
					withCredentials: true,
					xsrfCookieName: "csrftoken",
					xsrfHeaderName: "X-CSRFTOKEN",
				})
				.then((resp) => {
					if (resp.status === 201) {
						dispatch(updateFiltreCustomItem(resp.data));
					}
				})
				.catch((reason) => {
					console.log(reason);
				});
		} else if (reason === "selectOption") {
			let tmpF = JSON.parse(JSON.stringify(detail.option));
			tmpF.active = true;
			axios
				.put(UPDATE_FILTRE_CUSTOM_ITEM_URL, JSON.stringify(tmpF), {
					headers: {
						"Content-Type": "application/json",
					},
					withCredentials: true,
					xsrfCookieName: "csrftoken",
					xsrfHeaderName: "X-CSRFTOKEN",
				})
				.then((resp) => {
					if (resp.status === 201) {
						dispatch(updateFiltreCustomItem(resp.data));
					}
				})
				.catch((reason) => {
					console.log(reason);
				});
		} else if (reason === "updateOption") {
			let tmpF = JSON.parse(JSON.stringify(detail.option));
			tmpF.active = true;
			axios
				.put(UPDATE_FILTRE_CUSTOM_ITEM_URL, JSON.stringify(tmpF), {
					headers: {
						"Content-Type": "application/json",
					},
					withCredentials: true,
					xsrfCookieName: "csrftoken",
					xsrfHeaderName: "X-CSRFTOKEN",
				})
				.then((resp) => {
					if (resp.status === 201) {
						dispatch(updateFiltreCustomItem(resp.data));
					}
				})
				.catch((reason) => {
					console.log(reason);
				});
		} else if (reason === "updateVisible") {
			const data = { fid: filtreId, visible: detail.visible };
			axios
				.put(UPDATE_FILTRE_CUSTOM_VISIBILITY_URL, JSON.stringify(data), {
					headers: {
						"Content-Type": "application/json",
					},
					withCredentials: true,
					xsrfCookieName: "csrftoken",
					xsrfHeaderName: "X-CSRFTOKEN",
				})
				.then((resp) => {
					if (resp.status === 200) {
						dispatch(updateFiltreCustom(resp.data));
					}
				})
				.catch((reason) => {
					console.log(reason);
				});
		}
	};

	/**
	 *
	 * @param {*} res
	 */
	const handleAddFiltre = (res) => {
		dispatch(addFiltreCustom(res));
	};
	/**
	 *
	 * @param {*} res
	 */
	const handleAddCategorie = (res) => {
		dispatch(addCategorie(res));
	};

	return (
		<div style={{padding:"20px",paddingTop:"84px"}}>
			<CategorieNew handleAddCategorie={handleAddCategorie} />
			<br />

			<Card sx={{ minWidth: 600 }}>
				<CardContent sx={{ display: "flex", justifyContent: "left" }}>
					<Typography sx={{ fontSize: 30 }} color="text.secondary">
						Administration des Filtres
					</Typography>
				</CardContent>
				<FiltreCustomNew handleAddFiltre={handleAddFiltre} />

				<CardContent>
					<br />
					<br />
					<Divider>
						<Typography sx={{ fontSize: 20 }} color="text.secondary">
							Modification Filtres
						</Typography>
					</Divider>
					<br />

					<Grid container spacing={12}>
						{filtresCustom.map((fc) => (
							<Grid key={fc.id} xs={1} md={4} item>
								<FiltresCustomMenu
									key={fc.id}
									filtre={fc}
									isAdmin={true}
									handleFiltresCustomChanged={(r, d, fid) => handleFiltresCustomChanged(r, d, fid)}
									size={"small"}
								/>
							</Grid>
						))}
					</Grid>
				</CardContent>
			</Card>
			<br />
		</div>
	);
};

export default DocumentAdmin;
