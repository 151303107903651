import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sub } from "date-fns";
import axios from "axios";

const FILTRES_CUSTOM_URL = process.env.REACT_APP_ENDPOINT + "api/documents/filtrescustom";
const initialState = {
	filtresCustom: [],
	status: "idle",
	error: null,
};

export const fetchFiltresCustom = createAsyncThunk("filtres-custom/fetchFiltresCustom", async () => {
	const response = await axios.get(FILTRES_CUSTOM_URL, { withCredentials: true });
	return response.data;
});

const filtresCustomListSlice = createSlice({
	name: "filtresCustomList",
	initialState,
	reducers: {
		addFiltreCustomItem: (state, action) => {
			state.filtresCustom.forEach((fc, i) => {
				if (fc.id === action.payload.filtre_custom) {

					state.filtresCustom[i].items.push({
						id: action.payload.id,
						label: action.payload.label,
						active: true,
						filtre_custom: action.payload.filtre_custom,
					});

				}
			});
		},
		updateFiltreCustom: (state, action) => {
			state.filtresCustom.forEach((fc) => {
				if (fc.id === action.payload.id) {
					fc.label = action.payload.label;
					fc.visible = action.payload.visible;
				}
			});
		},

		addFiltreCustom: (state, action) => {
			state.filtresCustom = action.payload;
		},

		updateFiltreCustomItem: (state, action) => {
			state.filtresCustom.forEach((fc) => {
				if (fc.id === action.payload.filtre_custom) {
					fc.items.forEach((fci) => {
						if (fci.id === action.payload.id) {
							fci.active = action.payload.active;
							fci.label = action.payload.label;
						}
					});
				}
			});
		},
	},
	extraReducers(builder) {
		builder
			.addCase(fetchFiltresCustom.pending, (state, action) => {
				state.status = "loading";
			})
			.addCase(fetchFiltresCustom.fulfilled, (state, action) => {
				state.status = "succeded";
				state.filtresCustom = action.payload;
				state.filtresCustom.forEach((f) => (f.selected = []));
			})
			.addCase(fetchFiltresCustom.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			});
	},
});

export const selectAllFiltresCustom = (state) => state.filtresCustomList.filtresCustom;
export const getFiltresCustomStatus = (state) => state.filtresCustomList.status;
export const getFiltresCustomError = (state) => state.filtresCustomList.error;

export const { addFiltreCustomItem, updateFiltreCustomItem, updateFiltreCustom, addFiltreCustom } =
	filtresCustomListSlice.actions;

export default filtresCustomListSlice.reducer;
