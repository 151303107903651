import React, { useMemo } from "react";
import { nanoid } from "@reduxjs/toolkit";
import { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { isValid, parse, parseISO, format } from "date-fns";
import debounce from "lodash/debounce";
import { Box, Card, CardContent, Grid, TextField, Snackbar, Alert } from "@mui/material";

import { getCategorieSelected, selectAllCategories, setCategorieSelected } from "../categories/categoriesListSlice";
import { fetchDocuments, getDocsError, getDocsStatus, selectAllDocuments, updateDocuments } from "./documentsListSlice";
import {
	getFiltresCustomError,
	getFiltresCustomStatus,
	selectAllFiltresCustom,
} from "../filtres/filtres_custom/filtresCustomListSlice";

import { FiltresCustomMenu } from "../filtres/filtres_custom/FiltresCustom";

import DocumentUpdate from "./DocumentUpdate";

import DocInCard from "./DocumentCard";

export const URL_DOCUMENT = process.env.REACT_APP_ENDPOINT + "api/documents/download";
export const URL_UPDATE_DOCUMENT = process.env.REACT_APP_ENDPOINT + "api/documents/update";
export const URL_DOCUMENT_FILTERED_FULL_TEXT = process.env.REACT_APP_ENDPOINT + "api/documents/fts/?fulltext_search=";
export const URL_DOCUMENT_HISTORIQUE_COMPLET = process.env.REACT_APP_ENDPOINT + "api/documents/documenthistoriquecpt";

//#region the grid
const DocumentsPanel = () => {
	const spacing = 1;
	let tmp_allFiltersSelected = [];

	const dispatch = useDispatch();

	const categories = useSelector(selectAllCategories);
	const [flatCat, setFlatCat] = useState([]);

	const documents = useSelector(selectAllDocuments);
	const docError = useSelector(getDocsError);
	const docStatus = useSelector(getDocsStatus);

	const categorieSelected = useSelector(getCategorieSelected);

	const [documentsCategorized, setDocumentsCategorized] = useState(() => documents);
	const [documentsDisplayed, setDocumentsDisplayed] = useState(() => documents);
	const [documentsKeyWorded, setDocumentsKeyWorded] = useState([]);

	const [filtersSelectedList, setFiltersSelectedList] = useState([]);
	const [clearFiltersList, setClearFiltersList] = useState(true);

	const filtresCustom = useSelector(selectAllFiltresCustom);
	const filtresCustomStatus = useSelector(getFiltresCustomStatus);
	const filtresCustomError = useSelector(getFiltresCustomError);

	const [openUpdate, setOpenUpdate] = useState(false);
	const [dataDocUpdate, setDataDocUpdate] = useState([]);

	const [openSnack, setOpenSnack] = useState(false);
	const [snackSeverity, setSnackSeverity] = useState("success");
	const [snackMessage, setSnackMessage] = useState("Document modifié");

	useEffect(() => {
		if (docStatus === "idle") {
			dispatch(fetchDocuments());
		} else if (docStatus === "succeeded") {
			setDocsDisplayedCategorized();
		}
	}, [docStatus, documents]);

	/**
	 *
	 */
	useEffect(() => {
		// affichage initial selon la categorie:
		// pas de filtres
		if (docStatus === "succeeded") {
			if (flatCat.length === 0) {
				flattenCategories(categories);
			}

			setFiltersSelectedList([]); // remet à zero les filtres selectionnés
			setClearFiltersList(!!!clearFiltersList); // switch qui remet à zero les filtres autocomplete

			setDocsDisplayedCategorized();
		}
	}, [categorieSelected, docStatus]);

	/**
	 *
	 */
	const setDocsDisplayedCategorized = () => {
		if (categorieSelected < 0) {
			//tous les documents
			if (categorieSelected === -1) {
				setDocumentsCategorized(() => {
					return documents;
				});
				setDocumentsDisplayed(() => {
					return documents;
				});
			} 
			else if (categorieSelected === -2) {
				const catzied = documents.filter((d) => {
					return d.bookmarked;
				});
				setDocumentsCategorized(() => catzied);
				setDocumentsDisplayed(() => catzied);
			}
			else if (categorieSelected === -3) {
				const catzied = documents.filter((d) => {
					return d.important;
				});
				setDocumentsCategorized(() => catzied);
				setDocumentsDisplayed(() => catzied);
			}
		} else {
			const catzied = documents.filter((d) => {
				return d.categories.indexOf(categorieSelected) > -1;
			});
			setDocumentsCategorized(() => catzied);
			setDocumentsDisplayed(() => catzied);
		}

		// setDocumentsCategorized(() => catzied);
		// setDocumentsDisplayed(() => catzied);
	};

	/**
	 *
	 * @param {*} cats
	 */
	const flattenCategories = (cats) => {
		cats.map((c) => {
			const cc = { id: c.id, label: c.label, parent: c.parent };

			if (flatCat.map((m) => m.id).indexOf(cc.id) === -1) {
				setFlatCat((prev) => [...prev, cc]);
			}

			if (c.sub_categories.length > 0) {
				flattenCategories(c.sub_categories);
			}
		});
	};

	/**
	 *
	 */
	useEffect(() => {
		updateDocumentsToDisplay();
	}, [filtersSelectedList]);

	/**
	 *
	 */
	useEffect(() => {
		updateDocumentKeyWorded();
	}, [documentsKeyWorded]);

	/**
	 *
	 */
	const updateDocumentsToDisplay = () => {
		// tableau des documents pouvant être affivchés
		let documentsToDisplay = [];
		// s'il y a des filtres selectionnés
		if (filtersSelectedList != null && filtersSelectedList.length > 0) {
			// loop sur tous les documents de la categorie/rubrique
			documentsCategorized.map((d, i) => {
				// au debut on considère qu'il a tous les filtres
				let hasAllFilters = true;

				// loop sur tous les filtres séléctionnés
				filtersSelectedList.forEach((f_id) => {
					// si le filtre selectionné courrant n'existe pas dans
					// les filtres du doc courrant
					// flag "not has all filters"
					if (d.custom_filtre.indexOf(f_id) < 0) {
						hasAllFilters = false;
						return false;
					}
				});

				// si le doc a tous les filtres, on l'ajoute aux docs à afficher
				if (hasAllFilters) {
					documentsToDisplay.push(d);
				}
			});

			// mise à jour des docs à afficher
			setDocumentsDisplayed(documentsToDisplay);
		} else {
			setDocumentsDisplayed(documentsCategorized);
		}
	};

	/**
	 * lorque la recherche par mots clés est utilisée.
	 *
	 */
	const updateDocumentKeyWorded = () => {
		// tableau des documents pouvant être affivchés
		let documentsToDisplay = [];
		// s'il y a des filtres selectionnés
		if (documentsKeyWorded != null && documentsKeyWorded.length >= 0) {
			// loop sur tous les documents de la categorie/rubrique
			documentsCategorized.map((d, i) => {
				// au debut on considère qu'il n'y sont pas
				let isInKeyWorded = false;

				// loop sur tous les id de docs matchant les mots clés
				documentsKeyWorded.forEach((kw) => {
					if (d.id === kw.id) {
						isInKeyWorded = true;
					}
				});
				//  on l'ajoute aux docs à afficher
				if (isInKeyWorded) {
					documentsToDisplay.push(d);
				}
			});

			// mise à jour des docs à afficher
			setDocumentsDisplayed(documentsToDisplay);
		} else {
			setDocumentsDisplayed(documentsCategorized);
		}
	};

	/**
	 *
	 * @param {*} e
	 * @param {*} fc
	 */
	const handleFiltresCustomChanged = (reason, detail) => {
		// deep copy du state filtersSelectedList
		tmp_allFiltersSelected = JSON.parse(JSON.stringify(filtersSelectedList));

		// mise à jour des filtres selectionnés
		if (reason === "selectOption") {
			if (filtersSelectedList.indexOf(detail.option.id) < 0) tmp_allFiltersSelected.push(detail.option.id);
		} else if (reason === "removeOption") {
			tmp_allFiltersSelected.splice(filtersSelectedList.indexOf(detail.option.id), 1);
		}

		// maj state
		setFiltersSelectedList(tmp_allFiltersSelected);
	};

	/**
	 *
	 * @param {*} e
	 */
	const handleChangeFullText = (e) => {
		const regex = /\s/g;
		const regex2space = / +/g;
		let clean = e.target.value.replace(regex, " ").replace(regex2space, " ").replace(regex, ",");

		clean = clean
			.split(",")
			.filter((kw) => kw.length >= 3)
			.join(",");

		axios
			.get(URL_DOCUMENT_FILTERED_FULL_TEXT + clean, { withCredentials: true })
			.then((resp) => {
				setDocumentsKeyWorded(resp.data);
				// if(resp.data.length >= 0){
				// 	updateDocumentKeyWorded()
				// }
			})
			.catch((reason) => {})
			.finally(() => {});
	};

	/**
	 *
	 * @param {*} b : boolean
	 * @param {*} d : document data
	 */
	const handleSetOpenUpdate = (b, d) => {
		if (b) {
			setDataDocUpdate(d);
			setOpenUpdate(b);
		} else {
			setOpenUpdate(b);
			setDataDocUpdate([]);
		}
	};

	/**
	 *
	 * @param {*} docId
	 */
	const updateDocument = (docId, file, source_file, isNouvelleVersion,isImportant) => {
		documents.forEach((d) => {
			if (d.id === docId) {
				const formData = new FormData();

				let newFileName = d.nom_original;
				let nomOriginal = d.nom_original;
				if (file !== undefined) {
					formData.append("file_object", file);

					const regex = /\s/gi;
					nomOriginal = file.path;
					newFileName = file.path.replaceAll(regex, "_");
				}

				if (source_file !== undefined) {
					formData.append("source_file_object", source_file);

					// const regex = /\s/gi;
					// nomOriginal = source_file.path;
					// newFileName = source_file.path.replaceAll(regex, "_");
				}

				const display_name = d.display_name !== "" ? d.display_name : newFileName;
				const source_file_name = source_file ? source_file.path : d.source_file_name;
				let json = {
					did: docId,
					name: newFileName,
					name: nomOriginal,
					display_name: display_name,
					resume: d.resume,
					categories: d.categories,
					custom_filtre: d.custom_filtre,
					source_file_name: source_file_name,
					nouvelle_version: isNouvelleVersion,
					important:isImportant
				};

				formData.append("file_data", JSON.stringify(json));

				axios
					.put(URL_UPDATE_DOCUMENT, formData, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
						withCredentials: true,
						xsrfCookieName: "csrftoken",
						xsrfHeaderName: "X-CSRFToken",
					})
					.then((r) => {
						if (r.status === 200) {
							dispatch(updateDocuments(r.data));
							setOpenSnack((p) => true);
							setSnackSeverity((p) => "success");
							setSnackMessage((p) => "Document modifié");
							updateDocumentsToDisplay();
							// setDocsDisplayedCategorized();
						} else {
						}
					})
					.catch((err) => {
						setOpenSnack((p) => true);
						setSnackSeverity((p) => "error");
						setSnackMessage((p) => err.request.responseText);
					});
			}
		});
	};

	/**
	 *
	 */
	const handleCloseSnack = () => {
		setOpenSnack((p) => false);
	};
	/**
	 *
	 */
	const debouncedHandleChangeFullText = useMemo(() => debounce(handleChangeFullText, 1000), []);

	let content;
	if (docStatus === "idle") {
		content = <p>"Chargement..."</p>;
	} else if (docStatus === "succeeded") {
		content = (
			<>
				<Snackbar
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					open={openSnack}
					autoHideDuration={6000}
					onClose={handleCloseSnack}
				>
					<Alert onClose={handleCloseSnack} severity={snackSeverity} sx={{ width: "100%" }}>
						{snackMessage}
					</Alert>
				</Snackbar>
				<DocumentUpdate
					openUpdate={openUpdate}
					handleSetOpenUpdate={handleSetOpenUpdate}
					dataDoc={dataDocUpdate}
					updateDocument={updateDocument}
				/>

				<Card
					id="filtres-panel"
					sx={{
						display: "none",
						borderRadius: 0,
						position: "fixed",
						zIndex: 100,
						top: "64px",
						width: "calc(100vw - 240px)",
						backgroundColor: "secondary.light",
						// background: "#e0dede",
					
					}}
				>
					<CardContent>
						<Grid sx={{ flexGrow: 1 }} spacing={8} container>
							{filtresCustom.map(
								(fc) =>
									fc.visible && (
										<Grid key={fc.label + "_" + fc.id} item>
											<FiltresCustomMenu
												filtre={fc}
												handleFiltresCustomChanged={(r, d) => handleFiltresCustomChanged(r, d)}
												clearAutocomplete={clearFiltersList}
											/>
										</Grid>
									)
							)}

							<Grid item>
								<TextField
									sx={{ width: "400px" }}
									label="Recherche textuelle"
									placeholder="Recherche dans le résumé, le titre et les filtres."
									helperText="Mots d'au moins 3 lettres séparés par un espace"
									rows={3}
									variant="standard"
									onChange={(e) => debouncedHandleChangeFullText(e)}
								/>
							</Grid>
						</Grid>
					</CardContent>
				</Card>

				<Box id="card-content-layout" sx={{ paddingTop: "84px", paddingBottom: "20px" }}>
					<Grid sx={{ flexGrow: 1 }} container spacing={spacing}>
						<Grid item xs={12}>
							<Grid container justifyContent="space-around" spacing={spacing}>
								{documentsDisplayed.map((d) => (
									<Grid key={d.id} item>
										<DocInCard
											dataDoc={d}
											handleSetOpenUpdate={handleSetOpenUpdate}
											flatCategories={flatCat}
										/>
									</Grid>
								))}
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</>
		);
	} else if (docStatus === "failed") {
		content = <p>{docError}</p>;
	}

	return <>{content}</>;
};
//#endregion

export default DocumentsPanel;
