import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { convertISOStringToFrDate } from "../../../../app/helpers/date-helpers";

const Commentaire = ({ message }) => {
	const fontWeight = message.parent === null ? "bold" : undefined;
	const fontColor = "primary";
	return (
		<>
			<Box
				style={{
					paddingLeft: "15px",
					borderBottom: "1px solid #ccc",
					wordBreak: "break-word",
				}}
			>
				<Typography
					color={fontColor}
					style={{
						fontWeight: fontWeight,
						fontSize: "12px",
						wordBreak: "break-word",
					}}
				>	<span style={{fontSize:"10px", color:"#72444f"}}>{convertISOStringToFrDate(message.date)}</span><br />
					{message.commentaire}
				</Typography>
			</Box>
			{message.sub_commentaires.length > 0
				? message.sub_commentaires.map((sm) => {
						return (
							<div key={sm.id} style={{ paddingLeft: "15px" }}>
								<Commentaire message={sm} />
							</div>
						);
				  })
				: undefined}
		</>
	);
};

const CommentaireSmall = ({ messages }) => {
	const [mess, setMess] = useState([]);

	useEffect(() => {
		setMess((p) => messages);
	}, [messages]);
	return (
		<div
			className="SmallMessageContainer"
			style={{
				overflowY: "auto",
				// height: "fit-content",
				height: "700px",
				padding: "5px",
				marginLeft: "5px",
				paddingLeft: "5px",
				borderLeft: "1px solid #ccc",
			}}
		>
			{mess != undefined && mess.map((m) => {
				return (
					<div key={m.id} style={{ marginBottom: "20px" }}>
						<Commentaire message={m} />
					</div>
				);
			})}
		</div>
	);
};

export default CommentaireSmall;
