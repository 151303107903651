import {
	Autocomplete,
	Button,
	Chip,
	FormControl,
	FormHelperText,
	Menu,
	MenuItem,
	Stack,
	TextField,
	Tooltip,
} from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import { nanoid } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { selectAllDocuments } from "../documents/documentsListSlice";
import { setCategorieSelected } from "../categories/categoriesListSlice";
import { useDispatch } from "react-redux";
import { ExpandMore } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Box } from "@mui/system";

const Categorie = ({ cat, offsetLeft }) => {
	const hasChildrens = cat.sub_categories.length === 0 ? false : true;
	const firstLevel = cat.parent == null ? true : false;
	const [isOpen, setIsOpen] = useState(false);

	const dispatch = useDispatch();

	const toggleChilds = (e, cat_id) => {
		// console.log(e.target)
		// e.preventDefault()
		dispatch(setCategorieSelected(cat_id));
		const childs = e.target.parentNode.childNodes;

		childs.forEach((child) => {
			child.hidden = !child.hidden;
		});

		setIsOpen((p) => !!!p);
	};

	return (
		<div hidden={!firstLevel}>
			<div key={cat.id}>
				{cat.commentaire ? (
					<Tooltip title={cat.commentaire} placement="top" followCursor>
						<ListItemButton
							className="listMenuItemButton"
							id={"lib_"+cat.id}
							component={NavLink}
							to={"/document/documents/" + cat.id}
							onClick={(e) => toggleChilds(e, cat.id)}
							sx={{
								paddingLeft: offsetLeft + "px !important",
								width: "100%",
								display: "flex",
								justifyContent: "space-between",
							}}
						>
							{cat.label}
							{hasChildrens &&
								(!isOpen ? (
									<AddCircleOutlineIcon
										sx={{ marginRight: "3px", paddingBottom: "1px" }}
										color="#ccc"
										fontSize="12px"
										onClick={() => {document.getElementById("lib_"+cat.id).click();setIsOpen((p) => !!!p);}}
									/>
								) : (
									<RemoveCircleOutlineIcon
										sx={{ marginRight: "3px", paddingBottom: "1px" }}
										color="#ccc"
										fontSize="12px"
										onClick={() => {document.getElementById("lib_"+cat.id).click();setIsOpen((p) => !!!p);}}
									/>
								))}
							
						</ListItemButton>
					</Tooltip>
				) : (
					<>
						<ListItemButton
							className="listMenuItemButton"
							id={"lib_"+cat.id}
							component={NavLink}
							to={"/document/documents/" + cat.id}
							onClick={(e) => toggleChilds(e, cat.id)}
							sx={{
								paddingLeft: offsetLeft + "px !important",
								width: "100%",
								display: "flex",
								justifyContent: "space-between",
							}}
						>
							{cat.label}
							{hasChildrens &&
								(!isOpen ? (
									<AddCircleOutlineIcon
										sx={{ marginRight: "3px", paddingBottom: "1px" }}
										color="#ccc"
										fontSize="12px"
										onClick={() => {document.getElementById("lib_"+cat.id).click();setIsOpen((p) => !!!p);}}
									/>
								) : (
									<RemoveCircleOutlineIcon
										sx={{ marginRight: "3px", paddingBottom: "1px" }}
										color="#ccc"
										fontSize="12px"
										onClick={() => {document.getElementById("lib_"+cat.id).click();setIsOpen((p) => !!!p);}}
									/>
								))}
						</ListItemButton>
					</>
				)}

				{hasChildrens &&
					cat.sub_categories.map((child) => (
						<Categorie key={child.id} cat={child} offsetLeft={offsetLeft + 15} />
					))}
			</div>
		</div>
	);
};

/**
 *
 * @param {*} param0
 * @returns
 */
export const CategorieMenu = ({ cat, flatCat, handleCategorieChange }) => {
	const [categorieSelected, setCategorieSelected] = useState([]);
	const handleChange = (e, c) => {
		setCategorieSelected(c);
		handleCategorieChange(c);
	};

	return (
		<FormControl>
			<Autocomplete
				sx={{ width: "300px" }}
				options={flatCat}
				multiple
				getOptionLabel={(option) => option.label}
				defaultValue={[]}
				onChange={(e, v) => {
					handleChange(e, v);
				}}
				renderInput={(params) => <TextField {...params} variant="standard" label="Rubriques" />}
			/>
			<FormHelperText>Correspond au menu de gauche</FormHelperText>
		</FormControl>
	);
};

export default Categorie;
