import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import SearchIcon from "@mui/icons-material/Search";
import HomeIcon from "@mui/icons-material/Home";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import GridOnOutlinedIcon from "@mui/icons-material/GridOnOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import StarsIcon from "@mui/icons-material/Stars";
import StarOutlineIcon from '@mui/icons-material/StarOutline';

import DocumentsMenu from "./DocumentMenu";

import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
// import SearchAppBar from "../header/SearchAppBar";
import axios from "axios";
import useAuth from "../../app/hooks/useAuth";
import { Button, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { setCategorieSelected } from "../../features/documents/categories/categoriesListSlice";
import { calculateNewValue } from "@testing-library/user-event/dist/utils";
// import CategoriesList from "../../features/documents/categories/CategoriesList";

const drawerWidth = 240;

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		// marginLeft: drawerWidth,
		// width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

export default function MiniDrawer() {
	const navigate = useNavigate();
	const location = useLocation();

	const theme = useTheme();
	const [open, setOpen] = React.useState(true);
	const { auth, setAuth } = useAuth();

	const dispatch = useDispatch();

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const logoutClick = () => {
		axios
			.post(
				process.env.REACT_APP_ENDPOINT + "api/logout/",
				{},
				{ xsrfCookieName: "csrftoken", xsrfHeaderName: "X-CSRFTOKEN", withCredentials: true }
			)
			.then((r) => {
				// navigate("/login", { replace: true });
			})
			.finally(() => {
				localStorage.removeItem("user");
				setAuth(false);
				navigate("/login", { replace: true });
			});
	};

	/**
	 *
	 */
	const goHome = () => {
		navigate("/", { replace: true });
	};
	/**
	 *
	 */
	const allDocuments = () => {
		dispatch(setCategorieSelected(-1));
		navigate("/document/documents/all", { replace: true });
	};

	/**
	 *
	 */
	const favoritesDocuments = () => {
		dispatch(setCategorieSelected(-2));
		navigate("/document/documents/favs", { replace: true });
	};

	const importantsDocuments = () => {
		dispatch(setCategorieSelected(-3));
		navigate("/document/documents/imps", { replace: true });
	};

	const toggleFilters = (e) => {
		e.preventDefault();
		if (document.getElementById("filtres-panel") !== null) {
			if (
				document.getElementById("filtres-panel").style.display == "none" ||
				document.getElementById("filtres-panel").style.display == ""
			) {
				document.getElementById("filtres-panel").style.display = "block";

				const box = document.querySelector("#filtres-panel");
				const height = box.offsetHeight;

				document.getElementById("card-content-layout").style.transitionDuration = "300ms";
				document.getElementById("card-content-layout").style.transitionProperty = "margin-top";
				document.getElementById("card-content-layout").style.marginTop = height + "px";
			} else {
				document.getElementById("filtres-panel").style.display = "none";
				document.getElementById("card-content-layout").style.marginTop = "0px";
			}
		}
	};

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />
			<AppBar position="fixed" open={open}>
				<Toolbar>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							width: "100%",
							alignItems: "center",
						}}
					>
						<div style={{ display: "flex", alignItems: "center" }}>
							{/* <div>
								<IconButton
									color="inherit"
									aria-label="open drawer"
									onClick={handleDrawerOpen}
									edge="start"
									sx={{
										marginRight: 5,
										...(open && { display: "none" }),
									}}
								>
									<MenuIcon />
								</IconButton>
							</div> */}
							<div style={{ width: "214px" }}>
								<Typography variant="h6" sx={{ fontSize: "14px" }} noWrap component="div">
									Base Documentaire Ordesoft
									{/* BDO */}
								</Typography>
							</div>
							<div
								style={{
									display: "flex",
									// width: "400px",
									// marginLeft: "200px",
									justifyContent: " flex-start",
								}}
							>
								<Button
									onClick={goHome}
									sx={{ alignItems: "flex-end" }}
									color="inherit"
									label="Dash board"
								>
									<HomeOutlinedIcon fontSize="medium" sx={{ cursor: "pointer" }} />
									<Typography>Accueil</Typography>
								</Button>

								<Button
									onClick={allDocuments}
									sx={{ alignItems: "flex-end", ml: "20px" }}
									color="inherit"
									label="Page d'accueil"
								>
									<GridOnOutlinedIcon fontSize="medium" sx={{ cursor: "pointer" }} />
									<Typography>Documents</Typography>
								</Button>
								<Button
									onClick={importantsDocuments}
									sx={{ alignItems: "flex-end", ml: "20px" }}
									color="inherit"
									label="favoris"
								>
									<StarOutlineIcon />

									<Typography>Importants</Typography>
								</Button>
								<Button
									onClick={favoritesDocuments}
									sx={{ alignItems: "flex-end", ml: "20px" }}
									color="inherit"
									label="favoris"
								>
									<BookmarkBorderOutlinedIcon />

									<Typography>Favoris</Typography>
								</Button>

								{location.pathname.includes("document/documents") && (
									<>
										<Button
											onClick={toggleFilters}
											sx={{ alignItems: "flex-end", ml: "20px" }}
											color="inherit"
											label="filtres"
										>
											<FilterAltOutlinedIcon fontSize="medium" sx={{ cursor: "pointer" }} />
											{/* <SearchIcon onClick={toggleFilters} fontSize="large" sx={{ cursor: "pointer" }} /> */}
											<Typography>Filtres</Typography>
										</Button>
									</>
								)}
							</div>
						</div>

						<div style={{ display: "flex", alignItems: "center" }}>
							<div style={{}}>
								<Typography>{auth.user.first_name + " " + auth.user.last_name}</Typography>
							</div>
							<div style={{ paddingLeft: "8px" }}>
								<Tooltip title="Se déconnecter">
									<AccountCircleOutlinedIcon
										onClick={logoutClick}
										fontSize="large"
										sx={{ cursor: "pointer" }}
									/>
								</Tooltip>
							</div>
						</div>
						{/* <div style={{width:"400px"}}>
							<SearchAppBar />
						</div> */}
					</div>
				</Toolbar>
			</AppBar>

			<Drawer
				variant="permanent"
				open={open}
				id="main-menu"
				sx={{}}
				PaperProps={{
					sx: {
						backgroundColor: "secondary.light",
						// backgroundColor: "#e0dede",
						boxShadow: "inset 2px 0px 6px 0px #8c8c8c",
						marginTop:"64px",
						// marginBottom:"64px",
						height:"calc(100% - 64px)"
						
						
					},
				}}
			>
				{/* <DrawerHeader > */}
					{/* <IconButton onClick={handleDrawerClose}>
						{theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
					</IconButton> */}
				{/* </DrawerHeader> */}
				<Divider />
				<DocumentsMenu />
			</Drawer>

			<Box component="main" sx={{ height: "100vh", flexGrow: 1, p: 3, backgroundColor: "#e2e7f0" }}>
				{/* <Box component="main" sx={{ mt: "64px", height: "100vh", flexGrow: 1, p: 3, backgroundColor: "#f4f4f4" }}> */}
				{/* <DrawerHeader /> */}
				<Outlet />
			</Box>
		</Box>
	);
}
