import {
	Box,
	Button,
	Checkbox,
	ClickAwayListener,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Fade,
	FormControlLabel,
	Paper,
	Popper,
	TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAllCategories } from "../categories/categoriesListSlice";
// import CategorieTree from "../categories/CategorieTree";
import CategorieTreeUpdateDocument from "../categories/CategorieTreeUpdateDocument";
import { FiltresCustomMenu } from "../filtres/filtres_custom/FiltresCustom";
import { selectAllFiltresCustom } from "../filtres/filtres_custom/filtresCustomListSlice";
import FiltresCustomUpdateDocument from "../filtres/filtres_custom/FiltresCustomUpdateDocument";
import {
	updateDocCategorie,
	updateFileDoc,
	updateFiltreCustomDoc,
	updateNomAffichage,
	updateResume,
} from "./documentsListSlice";
import DocumentUpdateUploader from "./DocumentUpdateUploader";
import DocumentUploader from "./DocumentUploader";

const DocumentUpdate = ({ openUpdate, handleSetOpenUpdate, dataDoc, updateDocument }) => {
	// const [openUpdate, setOpenUpdate] = useState();
	const [open, setOpen] = useState(openUpdate);
	const [nom, setNom] = useState("");
	const [resume, setResume] = useState("");
	const [selectedFilters, setSelectedFilters] = useState([]);

	const filtresCustom = useSelector(selectAllFiltresCustom);
	const categories = useSelector(selectAllCategories);

	const [selectedCatLabel, setSelectedCatLabel] = useState("");
	const [flatCat, setFlatCat] = useState([]);

	const [anchorCatEl, setAnchorCatEl] = React.useState(null);
	const [openCatSelector, setOpenCatSelector] = useState(false);

	const [updatedDoc, setUpdatedDoc] = useState(dataDoc);

	const [updatedFile, setUpdatedFile] = useState();
	const [updatedSourceFile, setUpdatedSourceFile] = useState();

	const [docVersionChecked, setDocVersionChecked] = useState(true);
	const [docImportant, setDocImportant] = useState(true);

	const dispatch = useDispatch();

	useEffect(() => {}, []);

	/**
	 *
	 */
	useEffect(() => {
		setUpdatedDoc(() => dataDoc);
		flattenCategories(categories);

		if (dataDoc != [] && dataDoc.id) {
			setNom(() => dataDoc.display_name);
			setResume(() => dataDoc.resume);
			setSelectedFilters(() => dataDoc.custom_filtre);
			setDocImportant(p=>dataDoc.important)

			let selCatLab = "";
			setSelectedCatLabel(() => selCatLab);
			flatCat.forEach((fc) => {
				if (fc.id === dataDoc.categories[0]) {
					selCatLab = fc.label;
					setSelectedCatLabel(() => selCatLab);
				}
			});
			setSelectedCatLabel(() => selCatLab);
		} else {
			setNom(() => "");
			setResume(() => "");
			setSelectedFilters(() => []);
			setSelectedCatLabel(() => "");
		}
	}, [openUpdate]);

	/**
	 *
	 * @param {*} cats
	 */
	const flattenCategories = (cats) => {
		cats.map((c) => {
			const cc = { id: c.id, label: c.label, parent: c.parent };
			if (flatCat.map((m) => m.id).indexOf(cc.id) === -1) {
				setFlatCat((prev) => [...prev, cc]);
			}

			if (c.sub_categories.length > 0) {
				flattenCategories(c.sub_categories);
			}
		});
	};

	/**
	 * RETOUR au component parent
	 */
	const handleUpdateDocument = () => {
		updateDocument(updatedDoc.id, updatedFile, updatedSourceFile, docVersionChecked, docImportant);
		handleClose();
	};

	/**
	 *
	 */
	const handleClose = () => {
		setOpen(false);
		handleSetOpenUpdate(false);
		setNom(() => "");
		setResume(() => "");
		dataDoc = [];
	};

	/**
	 *
	 * @param {*} e
	 */
	const handleChangeNomAff = (e) => {
		e.preventDefault();
		setNom(() => e.target.value);

		dispatch(updateNomAffichage({ v: e.target.value, did: dataDoc.id }));
	};
	/**
	 *
	 * @param {*} e
	 */
	const handleChangeResume = (e) => {
		e.preventDefault();
		setResume(() => e.target.value);
		dispatch(updateResume({ v: e.target.value, did: dataDoc.id }));
	};

	/**
	 *
	 */
	const handleClickAway = () => {
		setOpenCatSelector(false);
	};
	/**
	 *
	 * @param {*} event
	 */
	const handleClickCategorieTextField = (event) => {
		setAnchorCatEl(event.currentTarget);
		setOpenCatSelector(p=>!p);
	};

	/**
	 *
	 * @param {*} e
	 * @param {*} s
	 */
	const handleChangeCategoryUpdate = (e, s) => {
		setSelectedCatLabel((p) => "");
		constructCategoryPath(s);

		const action = { v: [s.id], did: dataDoc.id };
		dispatch(updateDocCategorie(action));
	};

	/**
	 *
	 * @param {*} cat
	 */
	const constructCategoryPath = (cat) => {
		if (cat) {
			setSelectedCatLabel((prevPath) => {
				let path;
				if (prevPath === "") {
					path = cat.label;
				} else {
					path = cat.label + " / " + prevPath;
				}

				return path;
			});

			if (cat.parent != null) {
				const parent = flatCat.filter((x) => {
					return x.id == cat.parent;
				});
				constructCategoryPath(parent[0]);
			}
		}
	};

	/**
	 *
	 * @param {*} e event
	 * @param {*} r reason
	 * @param {*} d data
	 * @param {*} fid filtre id
	 */
	const handleFiltresCustomChanged = (e, r, d, fid) => {
		const action = { r: r, d: d.option.id, did: dataDoc.id };
		dispatch(updateFiltreCustomDoc(action));
	};

	/**
	 *
	 * @param {*} e
	 * @param {*} f file data
	 */
	const handleFilechanged = (f) => {
		setUpdatedFile((p) => f);
	};
	const handleFileSourcechanged = (f) => {
		setUpdatedSourceFile((p) => f);
	};
	const handleChangeVersionning = () => {
		setDocVersionChecked((pv) => {
			return !!!pv;
		});
	};
	const handleChangeImportant = () => {
		setDocImportant((pv) => {
			return !!!pv;
		});
	};

	return (
		<Dialog open={openUpdate} onClose={handleClose}>
			<DialogTitle>{dataDoc.display_name}</DialogTitle>
			<DialogContent sx={{ width: "600px" }}>
				<div style={{display:"flex", justifyContent: "space-between"}}>
				<FormControlLabel
					control={<Checkbox checked={docVersionChecked} onChange={handleChangeVersionning} size="small" />}
					label={`Nouvelle version (v${dataDoc.chrono} -> v${dataDoc.chrono + 1})`}
				/>
				<FormControlLabel
					control={<Checkbox checked={docImportant} onChange={handleChangeImportant} size="small" />}
					label={"Important"}
				/>
				</div>

				{/* fichier */}
				<div style={{marginTop:"20px", display: "flex", justifyContent: "space-between" }}>
					<div>
						<span style={{color:"#666",fontSize:"14px"}}>Fichier source</span>
						<DocumentUpdateUploader
							nom_original={dataDoc.source_file_name}
							handleFilechanged={handleFileSourcechanged}
							typeFichier="source"
						></DocumentUpdateUploader>
					</div>
					<div>
						<span style={{color:"#666",fontSize:"14px"}}>Fichier final visible par l'utilisateur</span>
						<DocumentUpdateUploader
							nom_original={dataDoc.nom_original}
							handleFilechanged={handleFilechanged}
							typeFichier="cible"
						></DocumentUpdateUploader>
					</div>
				</div>

				<div>
					{/* nom */}
					<TextField
						sx={{ mt: "20px", width: "300px" }}
						label="Nom d'affichage"
						placeholder="Nom d'affichage"
						variant="standard"
						size="small"
						// defaultValue={""}
						value={nom || ""}
						onChange={handleChangeNomAff}
					/>
				</div>
				{/* categorie */}
				<ClickAwayListener onClickAway={handleClickAway}>
					<Box>
						<TextField
							onClick={handleClickCategorieTextField}
							sx={{ mt: "20px", width: "100%" }}
							id="id-rubrique"
							label="Catégorie"
							variant="standard"
							value={selectedCatLabel}
							InputProps={{
								readOnly: true,
							}}
						></TextField>

						<Popper
							sx={{ zIndex: 10000 }}
							open={openCatSelector}
							anchorEl={anchorCatEl}
							placement={"bottom-start"}
							transition
						>
							{({ TransitionProps }) => (
								<Fade {...TransitionProps} timeout={50}>
									<Paper sx={{ padding: "10px", background: "#efefef" }}>
										{categories.length > 0 && (
											<Box>
												{categories.map((cat) => {
													return (
														<CategorieTreeUpdateDocument
															key={cat.label + "_" + cat.id}
															categorie={cat}
															handleChangeCategoryUpdate={(e, v) =>
																handleChangeCategoryUpdate(e, v)
															}
														/>
													);
												})}
											</Box>
										)}
									</Paper>
								</Fade>
							)}
						</Popper>
					</Box>
				</ClickAwayListener>
				{/* filtres */}
				<div style={{ display: "flex", flexDirection: "column" }}>
					{filtresCustom.map((fc) => (
						<Box key={fc.id + "_" + fc.label}>
							<FiltresCustomUpdateDocument
								key={fc.id}
								filtre={fc}
								selectedFilters={dataDoc.custom_filtre}
								updateDocument={false}
								handleFiltresCustomChanged={(e, r, d, fid) => handleFiltresCustomChanged(e, r, d, fid)}
							/>
						</Box>
					))}
				</div>
				{/* commentaire */}
				<TextField
					sx={{ mt: "20px", width: "100%" }}
					multiline
					label="Résumé"
					placeholder="Résumé"
					variant="standard"
					size="small"
					// defaultValue={""}
					value={resume || ""}
					onChange={handleChangeResume}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Annuler</Button>
				<Button onClick={handleUpdateDocument}>Valider</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DocumentUpdate;
