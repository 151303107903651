import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import Commentaire from "./Commentaire";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import axios from "axios";

export const URL_POST_DOCUMENT_MESSAGE = process.env.REACT_APP_ENDPOINT + "api/documents/postmessage";

const CommentairesDialog = ({ open, setOpen, commentaires, docid, updateComments }) => {
	const [commentaire, setCommentaire] = useState([]);
	const [newComment, setNewComment] = useState("");
	const newCommentZone = useRef();
	const newCommentInput = useRef();

	useEffect(() => {
		setCommentaire(commentaires);
	}, [commentaires]);

	/**
	 *
	 */
	const handleClose = () => {
		setOpen(false);
	};

	/**
	 *
	 */
	const sendNewCommentaire = () => {
		const data = { docid: docid, parent: null, value: newComment };
		axios
			.post(URL_POST_DOCUMENT_MESSAGE, JSON.stringify(data), {
				headers: {
					"Content-Type": "application/json",
				},
				withCredentials: true,
				xsrfCookieName: "csrftoken",
				xsrfHeaderName: "X-CSRFToken",
			})
			.then((r) => {
				setNewComment((p) => "");
				updateComments(r.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"md"}>
			<DialogContent>
				<DialogContentText></DialogContentText>
				{commentaires.map((c) => {
					return (
						<div key={c.id} style={{ marginTop: "15px", paddingTop: "10px", border: "1px solid #ccc" }}>
							<Commentaire com={c} offset={30} level={0} updateComments={updateComments} />
						</div>
					);
				})}
			</DialogContent>
			<DialogActions sx={{ justifyContent: "space-between" ,borderTop:"1px solid #ccc"}}>
				<div
					style={{
						color: "secondary",
						display: "flex",
						width: "100%",
						paddingLeft: "20px",
						paddingRight: "40px",
						paddingBottom: "15px",
						alignItems: "center",
					}}
				>
					<TextField
						inputRef={newCommentInput}
						onChange={(e) => {
							setNewComment((p) => e.target.value);
						}}
						style={{ width: "100%" }}
						value={newComment}
						label="Ajouter un commentaire"
						variant="standard"
						rows={1}
						multiline
					/>

					<div style={{ paddingLeft: "10px" }}></div>

					<SendOutlinedIcon color="primary" onClick={sendNewCommentaire} sx={{ cursor: "pointer" }} />
				</div>
				<div style={{ paddingRight: "15px" }}>
					<Button variant="outlined" onClick={handleClose}>
						Fermer
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	);
};

export default CommentairesDialog;
