import {
	Autocomplete,
	Box,
	Checkbox,
	Chip,
	FormControl,
	FormControlLabel,
	FormHelperText,
	InputLabel,
	makeStyles,
	MenuItem,
	OutlinedInput,
	Select,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
// import {makeStyles} from "@mui/styles"
import id from "date-fns/esm/locale/id/index.js";
import React, { useState } from "react";
import axios from "axios";
import { addFiltreCustomItem } from "./filtresCustomListSlice";
import { useDispatch } from "react-redux";
import { margin } from "@mui/system";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { useEffect } from "react";
import { nanoid } from "nanoid";

const FiltresCustom = () => {
	return <div>FiltresCustom</div>;
};

/**
 *
 * clearAutocomplete : un changement de valeur dans le component (ici key)
 * force le reset du component.
 * clearAutovomplete change dans le parent quand on change de categorie
 *
 * @param {*} param0
 * @returns
 */
export const FiltresCustomMenu = ({ filtre, handleFiltresCustomChanged, clearAutocomplete, isAdmin, size }) => {
	const [selectedFilters, setSelectedFilters] = useState(isAdmin ? filtre.items.filter((i) => i.active) : []);
	const [visible, setVisible] = useState(filtre.visible);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		setSelectedFilters((p) =>
			filtre.items.filter((i) => {
				return i.active;
			})
		);
	}, [filtre]);

	/**
	 *
	 * @param {*} e
	 * @param {*} v
	 * @param {*} r
	 * @param {*} d
	 * Methode utilisée pour l'utilisateur en recherche
	 */
	const handleChange = (e, v, r, d) => {
		e.preventDefault();
		handleFiltresCustomChanged(r, d, filtre.id);
	};

	/**
	 *
	 * @param {*} e
	 */
	const handleFiltresCustomKeyDown = (e) => {
		if (e.code === "Enter" && e.target.value) {
			const opt = { option: { id: null, label: e.target.value, active: true, filtre_custom: filtre.id } };
			handleFiltresCustomChanged("createOption", opt, filtre.id);
			e.target.blur();
		}
	};

	/**
	 *
	 * @param {*} e element
	 * @param {*} v valeur complete des items selectionnés
	 * @param {*} r action effectuée (add,remove,create...)
	 * @param {*} d valeur add ou delete (ou create)
	 */
	const handleChangeAdmin = (e, v, r, d) => {
		e.preventDefault();
		if (r === "selectOption" || r === "removeOption") {
			if (r === "removeOption") {
				let tmpOpt = { option: d };
				handleFiltresCustomChanged(r, tmpOpt, filtre.id);
				setSelectedFilters((x) => {
					return x.filter((y) => y.id !== tmpOpt.option.id);
				});
			} else {
				handleFiltresCustomChanged(r, d, filtre.id);
				setSelectedFilters(() => {
					return v;
				});
			}
		}
	};

	/**
	 *
	 * @param {*} e
	 */
	const handleDeleteChip = (e) => {
		console.log(e);
	};

	/**
	 *
	 * @param {*} e event
	 * @param {*} i item
	 */
	const handleChangeItemLabel = (e, i) => {
		e.preventDefault();
		let tmpItem = JSON.parse(JSON.stringify(i));
		tmpItem.label = e.target.textContent;
		handleFiltresCustomChanged("updateOption", { option: tmpItem }, filtre.id);
	};

	const handleChangeVisible = (e) => {
		// e.preventDefault()
		setVisible((pv) => {
			return !!!pv;
		});
		handleFiltresCustomChanged("updateVisible", { visible: !visible }, filtre.id);
	};

	return (
		<>
			{isAdmin ? ( // administration des filtres,
				// vaudrait peut être le coup d'en faire un component pour que ce soit plus lisible?
				<>
					<FormControlLabel
						control={<Checkbox checked={visible} onChange={handleChangeVisible} size="small" />}
						label={filtre.label + " Visible "}
					/>
					<Autocomplete
						size="small"
						className="filtre-custom"
						multiple
						autoComplete
						freeSolo
						filterSelectedOptions
						options={filtre.items}
						getOptionLabel={(f) => f.label || f}
						value={selectedFilters}
						defaultValue={[]}
						isOptionEqualToValue={(opt, val) => {
							return opt.id === val.id;
						}}
						onChange={(e, v, r, d) => {
							handleChangeAdmin(e, v, r, d);
						}}
						renderTags={() => null}
						renderInput={(params) => (
							<TextField
								size="small"
								{...params}
								variant="standard"
								label={filtre.label + " : ajouter ou réactiver des options"}
								onKeyDown={handleFiltresCustomKeyDown}
							/>
						)}
					/>

					<Box sx={{ display: "flex" }}>
						<Typography sx={{ mt: "20px", flexWrap: "wrap", flexDirection: "row" }}>
							Items activés,{" "}
						</Typography>
						<RemoveCircleOutlineOutlinedIcon fontSize="small" sx={{ mt: "20px", color: "#888" }} />
						<Typography sx={{ mt: "20px", flexWrap: "wrap", flexDirection: "row" }}>
							{" "}
							pour désactiver, click pour modifier le libellé:
						</Typography>
					</Box>
					<Stack sx={{ mt: "5px", flexWrap: "wrap", flexDirection: "row" }}>
						{selectedFilters.map((v) => {
							return (
								<Box
									sx={{
										padding: "3px",
										border: "1px solid #ccc",
										margin: "2px",
										borderRadius: "14px",
										lineHeight: "14px",
										display: "flex",
										alignItems: "center",
										width: "fit-content",
										background: "#eee",
									}}
									key={v.label + "_" + v.id + "_" + v.filtre_custom}
								>
									<Box>
										<span
											className="invisible-input"
											role="textbox"
											onBlur={(e) => {
												handleChangeItemLabel(e, v);
											}}
											contentEditable="true"
											suppressContentEditableWarning={true}
										>
											{v.label}
										</span>
									</Box>
									<RemoveCircleOutlineOutlinedIcon
										onClick={(e) => {
											handleChangeAdmin(e, null, "removeOption", v);
										}}
										fontSize="small"
										sx={{ color: "#888", cursor: "pointer" }}
									/>
								</Box>
							);
						})}
					</Stack>
				</>
			) : (
				<Autocomplete
					id={nanoid()}
					size="small"
					// key={clearAutocomplete}
					className="filtre-custom"
					open={open}
					onBlur={() => setOpen(() => false)}
					sx={{ width: "300px" }}
					multiple
					autoComplete
					freeSolo
					filterSelectedOptions
					options={filtre.items.filter((it) => {
						return it.active;
					})}
					getOptionLabel={(f) => f.label || f}
					defaultValue={[]}
					onChange={(e, v, r, d) => {
						handleChange(e, v, r, d);
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							onClick={() => setOpen(() => true)}
							variant="standard"
							size="small"
							label={filtre.label}
						/>
					)}
				/>
			)}
		</>
	);
};

export class FiltreCustom {
	constructor() {
		// id
		// items
		// label
	}
}

export const POST_FILTRE_CUSTOM_ITEM_URL = process.env.REACT_APP_ENDPOINT + "api/documents/filtrescustomitem";
export const UPDATE_FILTRE_CUSTOM_ITEM_URL = process.env.REACT_APP_ENDPOINT + "api/documents/filtrescustomitem";
export const UPDATE_FILTRE_CUSTOM_VISIBILITY_URL =
	process.env.REACT_APP_ENDPOINT + "api/documents/filtrescustomvisibility";

export default FiltresCustom;
