import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const GROUPS_URL = process.env.REACT_APP_ENDPOINT + "api/user/groups";

const initialState = {
	groups: [],
	status: "idle",
	error: null,
};

export const fetchGroups = createAsyncThunk("groups/fetchGroups", async () => {
	const response = await axios.get(GROUPS_URL, { withCredentials: true });
	return response.data;
});

const groupsListSlice = createSlice({
	name: "groupsList",
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(fetchGroups.pending, (state, action) => {
				state.status = "loading";
			})
			.addCase(fetchGroups.fulfilled, (state, action) => {
				state.status = "succeded";
				state.groups = action.payload;
			})
			.addCase(fetchGroups.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			});
	},

});

export const selectAllGroups = (state) => state.groupsList.groups;
export const getGroupsStatus = (state) => state.groupsList.status;
export const getGroupsError = (state) => state.groupsList.error;
export default groupsListSlice.reducer;