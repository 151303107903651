// @flow
import { Alert, Box, Button, Card, CardActions, CardContent, Divider, Snackbar, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { MuiChipsInput } from "mui-chips-input";
import axios from "axios";

const FiltreCustomNew = ({handleAddFiltre}) => {
	const POST_FILTRE_CUSTOM_URL = process.env.REACT_APP_ENDPOINT + "api/documents/filtrescustom";

	const [filtreLabel, setFiltreLabel] = useState("");
	const [chips, setChips] = useState([]);

	const [openSnack, setOpenSnack] = useState(false);
	const [snackSeverity, setSnackSeverity] = useState("success");
	const [snackMessage, setSnackMessage] = useState("Filtre enregistré");

	const handleChangeLabel = (e) => {
		setFiltreLabel(e.target.value);
	};
	const handleChangeChips = (newChips) => {
		setChips(newChips);
	};

	const handleCloseSnack = () => {
		setOpenSnack((p) => false);
	};

	const postNewFiltre = async () => {
		const data = { label: filtreLabel, items: chips };

		try {
			const res = await axios.post(POST_FILTRE_CUSTOM_URL, JSON.stringify(data), {
				headers: {
					"Content-Type": "application/json",
				},
				xsrfCookieName: "csrftoken",
				xsrfHeaderName: "X-CSRFTOKEN",
				withCredentials: true,
			});

			if (res.status === 201) {
				
				

				setChips((p)=>[])
				setFiltreLabel(p=>"")
				setOpenSnack((p) => true);
				setSnackSeverity((p) => "success");
				setSnackMessage((p) => "Filtre enrgistré");

				handleAddFiltre(res.data)
			}
		} catch (err) {
			setOpenSnack((p) => true);
			setSnackSeverity((p) => "error");
			setSnackMessage((p) => err.request.responseText);
		}
	};

	return (
		// <Card sx={{ minWidth: 600 }}>
		<>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				open={openSnack}
				autoHideDuration={6000}
				onClose={handleCloseSnack}
			>
				<Alert onClose={handleCloseSnack} severity={snackSeverity} sx={{ width: "100%" }}>
					{snackMessage}
				</Alert>
			</Snackbar>
			<CardContent>
				<Divider>
					<Typography sx={{ fontSize: 20 }} color="text.secondary">
						Nouveau Filtre
					</Typography>
				</Divider>
				<br />
				<Box sx={{ display: "flex" }}>
					<TextField
						value={filtreLabel}
						onChange={handleChangeLabel}
						sx={{ width: 200 }}
						id="nom-filtre-custom"
						label="Nom Nouveau Filtre"
						variant="standard"
					/>

					<MuiChipsInput
						value={chips}
						onChange={handleChangeChips}
						variant="standard"
						placeholder="Entrez des items + entrée pour valider"
						sx={{ width: 400, marginLeft: 2 }}
					/>
					<Button sx={{ marginLeft: 2 }} size="small" variant="outlined" onClick={postNewFiltre}>
						Enregistrer
					</Button>
				</Box>
			</CardContent>
			{/* <CardActions>
				<Button size="small" variant="outlined">
					Valider
				</Button>
			</CardActions> */}
		</>
		// </Card>
	);
};

export default FiltreCustomNew;
