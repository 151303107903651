import axios from "axios";
import { useEffect } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../app/hooks/useAuth";

const RequireAuth = () => {
	const RETRY_AUTH = process.env.REACT_APP_ENDPOINT + "api/user/get_my_profile";
	const { auth, setAuth } = useAuth();
	const location = useLocation();

	useEffect(() => {
		if (!auth?.user) {
			if (localStorage.getItem("user")) {
				axios
					.get(RETRY_AUTH, { withCredentials: true })
					.then((res) => {
						localStorage.removeItem("user");
						const user = res.data;
						setAuth({ user });
						const userLS = {email:user.email,first_name:user.first_name,last_name:user.last_name,username:user.username}
						localStorage.setItem('user',JSON.stringify(userLS))
					})
					.catch((e) => {
						localStorage.removeItem("user");
					});
			}
		}
	}, []);

	// return auth?.user ? <Navigate to="/dashboard/" state={{ from: "home" }} replace /> : <Navigate to="/login" state={{ from: "home" }} replace />;
	return auth?.user ? <Outlet /> : <Navigate to="/login" state={{ from: "home" }} replace />;
};

export default RequireAuth;
