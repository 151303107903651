import {
	Autocomplete,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";

const UserList = () => {
	const GET_USERS_URL = process.env.REACT_APP_ENDPOINT + "api/user/users";
	const GET_USERS_ROLES = process.env.REACT_APP_ENDPOINT + "api/user/roles";

	const [users, setUsers] = useState([]);
	const [roles, setRoles] = useState([]);
	const [entites, setEntites] = useState({});

	useEffect(() => {
		axios
			.get(GET_USERS_ROLES, {
				headers: {
					"Content-Type": "application/json",
				},
				xsrfCookieName: "csrftoken",
				xsrfHeaderName: "X-CSRFTOKEN",
				withCredentials: true,
			})
			.then((r) => {
				setRoles((pr) => {
					return r.data.g;
				});

				let dispatchedEntities = {};
				setEntites((pr) => {
					r.data.e.forEach((e) => {
						if (dispatchedEntities[e.type] === undefined) {
							dispatchedEntities[e.type] = [];
						}
						dispatchedEntities[e.type].push(e);
					});

					return dispatchedEntities;
				});
			});

		axios
			.get(GET_USERS_URL, {
				headers: {
					"Content-Type": "application/json",
				},
				xsrfCookieName: "csrftoken",
				xsrfHeaderName: "X-CSRFTOKEN",
				withCredentials: true,
			})
			.then((r) => {
				// console.log(r.data)
				setUsers((pu) => {
					return r.data;
				});
			});
	}, []);

	return (
		<div style={{paddingTop:"64px",margin:"20px"}}>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="a dense table">
					<TableHead>
						<TableRow>
							<TableCell>ID</TableCell>
							<TableCell>Utilisateur</TableCell>
							<TableCell>Email</TableCell>
							<TableCell>Nom</TableCell>
							<TableCell>Prénom</TableCell>
							<TableCell>Role</TableCell>
							{Object.entries(entites).map((e, i) => {
								return <TableCell key={e[0]}>{e[0]}</TableCell>;
							})}
						</TableRow>
					</TableHead>
					<TableBody>
						{users.map((row) => (
							<TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
								<TableCell component="th" scope="row">
									{row.id}
								</TableCell>
								<TableCell component="th" scope="row">
									{row.username}
								</TableCell>
								<TableCell>{row.email}</TableCell>
								<TableCell>{row.last_name}</TableCell>
								<TableCell>{row.first_name}</TableCell>
								<TableCell>
									<Autocomplete
										size="small"
										multiple
										id="tags-standard"
										options={roles}
										getOptionLabel={(option) => option.name}
										defaultValue={row.groups}
										isOptionEqualToValue={(opt, val) => {
											return opt.id === val.id;
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="standard"
												label="Roles utilisateur"
												placeholder="Roles"
											/>
										)}
									/>
								</TableCell>
								{Object.entries(entites).map((e, i) => {

									const vals = row.entite.filter(re => re.type === e[0])

									return (
										<TableCell key={e[0]}>
											<Autocomplete
												size="small"
												multiple
												id="tags-standard"
												options={e[1]}
												getOptionLabel={(option) => option.nom}
												defaultValue={vals}
												isOptionEqualToValue={(opt, val) => {
													return opt.id === val.id;
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														variant="standard"
														label={e[0]}
														placeholder={e[0]}
													/>
												)}
											/>
										</TableCell>
									);
								})}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
};

export default UserList;
