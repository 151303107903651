import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sub } from "date-fns";
import axios from "axios";

const KEYWORDS_URL = process.env.REACT_APP_ENDPOINT+"api/documents/keywords";
const initialState = {
	keywords: [],
	status: "idle",
	error: null,
};

export const fetchKeywords = createAsyncThunk("keywords/fetchKeywords", async () => {
	const response = await axios.get(KEYWORDS_URL);
	return response.data;
});

const keywordsListSlice = createSlice({
	name: "keywordsList",
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(fetchKeywords.pending, (state, action) => {
				state.status = "loading";
			})
			.addCase(fetchKeywords.fulfilled, (state, action) => {
				state.status = "succeded";
				state.keywords = action.payload;
			})
			.addCase(fetchKeywords.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			});
	},
});

export const selectAllKeywords = (state) => state.keywordsList.keywords;
export const getKeywordsStatus = (state) => state.keywordsList.status;
export const getKeywordsError = (state) => state.keywordsList.error;

export default keywordsListSlice.reducer;
